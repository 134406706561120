import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import dayjs from "dayjs";
import $env from "@/env";
var buildingsID = 4; // IMPORTANT
var excelID = 5; // IMPORTANT

//- flags.isPdfMassErmVomGrundbuch
//- flags.isPdfNoContactAvailable
//- flags.isPdfNoDocsAvailable

export default {
  methods: {
    MakeHtml: function MakeHtml(Bericht, imageTree, flags, currentProject) {
      var HTML = "";
      var _iterator = _createForOfIteratorHelper(Bericht),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (item.type == "head") {
            HTML += this.make_head_Pdf(item, flags, currentProject);
          }
          if (item.type == "headimage" || item.type == "mapimage") {
            HTML += this.make_image_Pdf(item);
          }
          if (item.type == "headcalculation") {
            HTML += this.make_headcalculation_Pdf(item, Bericht[excelID], flags);
          }
          if (item.type == "buildings") {
            HTML += this.make_buildings_Pdf(item);
          }
          if (item.type == "excel") {
            HTML += this.make_excel_Pdf(item, Bericht[buildingsID]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      HTML += this.add_allimages_Pdf(imageTree);
      return HTML;
    },
    make_head_Pdf: function make_head_Pdf(item, flags, currentProject) {
      var HTML = '';
      HTML += '<div class="mainblock head"> <h2 class="tac">' + item.title + "</h2>";
      HTML += "<div class='flex'>";
      var auftraggeber;
      var riskaddress;
      var polnr;
      var baujahr;
      var gewerbe;
      var wohneinheiten;
      var betriebsart;
      var renovierung;
      var denkmalschutz;
      var begehung;
      var stichtag;
      var VN;
      var _iterator2 = _createForOfIteratorHelper(item.values),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var tmp = _step2.value;
          if (tmp.name == "Auftraggeber") auftraggeber = tmp.value;
          if (tmp.name == "Baujahr (ca.)") baujahr = tmp.value;
          if (tmp.name == "Wohneinheiten (ca.)") wohneinheiten = tmp.value;
          if (tmp.name == "Letzte Renovierung (ca.)") renovierung = tmp.value;
          if (tmp.name == "Begehung") {
            var bDate = ((currentProject.svData || {}).besichtigungsDatum || '').substring(0, 10);
            begehung = dayjs(bDate).format('DD.MM.YYYY');
          }
          if (tmp.name == "VN") VN = tmp.value;
          if (tmp.name == "Risikoadresse") riskaddress = tmp.value;
          if (tmp.name == "Pol.Nr") polnr = tmp.value;
          if (tmp.name == "Gewerbliche Nutzung") {
            var Val3 = '';
            if (typeof tmp.value3 == 'string') {
              Val3 = tmp.value3;
            } else {
              Val3 = tmp.value3.join(", ");
            }
            window.LOG.red(Val3);
            gewerbe = tmp.value == "JA"
            // ? tmp.value2 + " % / " + tmp.value3
            ? tmp.value + ', Fläche: ' + (tmp.value2 !== 'KEINE ANGABE' ? tmp.value2 + " %" : tmp.value2) : tmp.value;
            betriebsart = tmp.value == "JA" ? Val3 : '';
          }
          // if (tmp.name == "Denkmalschutz") denkmalschutz = tmp.value
          if (tmp.name == "Denkmalschutz") {
            var ds = currentProject.flags.isDenkmalschutz;
            denkmalschutz = ds ? "JA" : "NEIN";
          }
          if (tmp.name == "Stichtag") stichtag = dayjs(tmp.value).format('DD.MM.YYYY');
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      HTML += "<table> ";
      HTML += "<tbody> ";
      HTML += "<tr> ";
      HTML += '<td colspan="2" rowspan="1" style="width: 50%;border-bottom:1px solid #fff;">Auftraggeber</td>';
      HTML += "<td class='bold'>VN:</td> ";
      HTML += "<td>" + VN + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += '<td colspan="2" rowspan="2" style="width: 50%;">' + auftraggeber + "</td>";
      HTML += "<td class='bold'>Risikoadresse:</td> ";
      HTML += "<td>" + riskaddress + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += "<td class='bold'>Pol.Nr.:</td> ";
      HTML += "<td>" + polnr + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += "<td>Baujahr (ca.):</td> ";
      HTML += "<td>" + (baujahr || '-') + "</td> ";
      HTML += "<td>Gewerbliche Nutzung:</td> ";
      HTML += "<td>" + (gewerbe || '-') + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += "<td>Wohneinheiten (ca.):</td> ";
      HTML += "<td>" + (wohneinheiten || '-') + "</td> ";
      HTML += "<td>Betriebsart:</td> ";
      HTML += "<td>" + (betriebsart ? betriebsart : "-") + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += "<td>Letzte Renovierung (ca.):</td> ";
      HTML += "<td>" + (renovierung || '-') + "</td> ";
      HTML += "<td>Denkmalschutz:</td> ";
      HTML += "<td>" + (denkmalschutz || '-') + "</td> ";
      HTML += "</tr> ";
      HTML += "<tr> ";
      HTML += "<td>Begehung:</td> ";
      HTML += "<td>" + begehung + "</td> ";
      HTML += "<td>Stichtag:</td> ";
      HTML += "<td>" + stichtag + "</td> ";
      HTML += "</tr> ";
      HTML += "</tbody> ";
      HTML += "</table> ";
      HTML += "</div>";
      HTML += "</div>";
      flags.isPdfNoDocsAvailable && (HTML += '<div class="hvwwarner">' + 'Keine Planunterlagen von Versicherungsnehmer/Verwalter zur Verfügung gestellt' + "</div>");
      flags.isPdfNoContactAvailable && (HTML += '<div class="hvwwarner">' + 'Keine Kontaktdaten von Allianz zur Verfügung gestellt' + "</div>");
      HTML += '<div class="spacer horizontal"></div>';
      return HTML;
    },
    make_image_Pdf: function make_image_Pdf(item) {
      var HTML = '';
      var extraclasses = Array.isArray(item.classes) ? item.classes.join(' ') : '';
      if (item.type == "mapimage") HTML += '<div class="pagebreak"></div>';
      HTML += "<div><ul>";
      window.LOG.red(item);
      window.LOG.log(item.values);
      var _iterator3 = _createForOfIteratorHelper(item.values),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var tmp = _step3.value;
          // console.log(encodeURI(tmp.url))
          // HTML += ' <li class="li_' + item.type + ' ' + extraclasses + '">'
          // HTML += '<img alt="image" class="' + item.type + " " + extraclasses + '" src="' + tmp.url + '"/>'

          HTML += ' <li class="li_bgimg ' + item.type + ' ' + extraclasses + '">';
          HTML += ' <div class="bg-image ' + item.type + '" style="background-image:url(\' ' + encodeURI(tmp.url) + '\')"></div>';
          HTML += '<p class="caption"><span class="bold">Abb.: </span>' + tmp.caption + "</p> </li>";
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      HTML += "</ul></div>";
      return HTML;
    },
    make_headcalculation_Pdf: function make_headcalculation_Pdf(item, excelItem, flags) {
      var HTML = '';
      HTML += '<div class="mainblock headcalc">';
      HTML += '<div class="headcalc">';
      HTML += "<h2>Gebäudeneubauwert </h2>";
      HTML += "<h4>ZUSAMMENFASSUNG SUMMEN</h4>";
      HTML += "<table>";
      if (excelItem.variant == 'v1') {
        HTML += "<thead>";
        HTML += "<tr>";
        HTML += "<th class='tal'>Bezeichnung</th>";
        HTML += "<th>BGF</th>";
        HTML += "<th>Umbauter Raum</th>";
        HTML += "<th>NBW</th>";
        HTML += "</tr>";
        HTML += "</thead>";
        HTML += "<tbody>";
        var _iterator4 = _createForOfIteratorHelper(excelItem.table.allFloorsData),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var m = _step4.value;
            HTML += "<tr>";
            HTML += "<td>" + m.name + "</td>";
            HTML += "<td class='tar'>" + this.$_roundMoney(m.area, 2) + "&nbsp;m<sup>2</sup> </td>";
            HTML += "<td class='tar'>" + this.$_roundMoney(m.vol, 2) + "&nbsp;m<sup>3</sup></td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(m.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        var _iterator5 = _createForOfIteratorHelper(excelItem.table.misc),
          _step5;
        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var _m = _step5.value;
            HTML += "<tr>";
            HTML += "<td>" + _m.name + "</td>";
            HTML += "<td></td>";
            HTML += "<td></td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(_m.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
        HTML += "</tbody>";
      } else if (excelItem.variant == 'v2') {
        HTML += "<thead>";
        HTML += "<tr>";
        HTML += "<th class='tal'>Bezeichnung</th>";
        HTML += "<th class='tar'>WNFL</th>";
        HTML += "<th class='tar'>NBW</th>";
        HTML += "</tr>";
        HTML += "</thead>";
        HTML += "<tbody>";
        var _iterator6 = _createForOfIteratorHelper(excelItem.table.allFloorsData),
          _step6;
        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var _m2 = _step6.value;
            HTML += "<tr>";
            HTML += "<td>" + _m2.name + "</td>";
            HTML += "<td class='tar'>" + this.$_roundMoney(_m2.area, 2) + "&nbsp;m<sup>2</sup> </td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(_m2.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }
        var _iterator7 = _createForOfIteratorHelper(excelItem.table.misc),
          _step7;
        try {
          for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
            var _m3 = _step7.value;
            HTML += "<tr>";
            HTML += "<td>" + _m3.name + "</td>";
            HTML += "<td></td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(_m3.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator7.e(err);
        } finally {
          _iterator7.f();
        }
        HTML += "</tbody>";
      } else if (excelItem.variant == 'v3') {
        HTML += "<thead>";
        HTML += "<tr>";
        HTML += "<th class='tal'>Bezeichnung</th>";
        HTML += "<th class='tar'>NBW</th>";
        HTML += "</tr>";
        HTML += "</thead>";
        HTML += "<tbody>";
        var _iterator8 = _createForOfIteratorHelper(excelItem.table.allFloorsData),
          _step8;
        try {
          for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
            var _m4 = _step8.value;
            HTML += "<tr>";
            HTML += "<td>" + _m4.name + "</td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(_m4.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator8.e(err);
        } finally {
          _iterator8.f();
        }
        var _iterator9 = _createForOfIteratorHelper(excelItem.table.misc),
          _step9;
        try {
          for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
            var _m5 = _step9.value;
            HTML += "<tr>";
            HTML += "<td>" + _m5.name + "</td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(_m5.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator9.e(err);
        } finally {
          _iterator9.f();
        }
        HTML += "</tbody>";
      }
      HTML += "</table>";

      // HTML += "<hr />"

      HTML += "<div class='nobreak'>";
      HTML += '<div class="subtext">inkl. Planungs- und Konstruktionskosten exkl. Steuer </div>';
      HTML += '<div class="subtext">Alle Werte gerundet und ohne Mehrwertsteuer angegeben. </div>';
      HTML += '<div class="endvalue"> €&nbsp;' + this.$_ceilMoney(excelItem.table.total.cost, -3) + "</div>";
      flags.isPdfMassErmVomGrundbuch && (HTML += '<div class="hvwwarner subtext clear">' + 'Die Berechnung der Bruttogrundflächen (BGF) und des umbauten Raumes wurde mittels Grundbuchsauszug sowie der vor Ort erhobenen Baumassen (Naturmaßaufnahme mittels Lasermessgerät) durchgeführt. Die digitalen Katasterbaumaße (wenn vorhanden) wurden hierfür ebenso herangezogen.' + "</div>");
      HTML += "</div>";
      HTML += "</div>";
      HTML += "</div>";
      return HTML;
    },
    make_buildings_Pdf: function make_buildings_Pdf(item) {
      var HTML = '';
      for (var b in item.buildings) {
        for (var bx in item.buildings[b]) {
          var itm = item.buildings[b][bx];
          /**************************************************/
          if (itm.type == "binfo") {
            HTML += '<div class="pagebreak"></div>';
            HTML += '<div class="mainblock list"> <h3>' + itm.values[0].value + "</h3>";
            HTML += "</div>";
          }
          /**************************************************/
          if (itm.type == "eckdaten" || itm.type == "nutzung") {
            HTML += '<div class="mainblock list"> <h4>' + itm.title + "</h4>";
            HTML += "<table> ";
            HTML += "<tbody> ";
            var _iterator10 = _createForOfIteratorHelper(itm.values),
              _step10;
            try {
              for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
                var val = _step10.value;
                HTML += "<tr> ";
                HTML += "<td class='mbold'>" + val.name + "</td> ";
                HTML += "<td>" + (Array.isArray(val.value) ? val.value.join(", ") : val.value) + "</td> ";
                HTML += "</tr> ";
              }
            } catch (err) {
              _iterator10.e(err);
            } finally {
              _iterator10.f();
            }
            HTML += "</tbody> ";
            HTML += "</table> ";
            HTML += "</div>";
          }
          /********************* LIST *****************************/
          if (itm.type == "list") {
            HTML += '<div class="mainblock list"> <h4>' + itm.title + "</h4>";
            HTML += "<table> ";
            HTML += "<tbody> ";
            var _iterator11 = _createForOfIteratorHelper(itm.values),
              _step11;
            try {
              for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
                var _val = _step11.value;
                HTML += "<tr> ";
                HTML += "<td class='mbold'>" + _val.name + "</td> ";
                var arr_diff = this.$_arr_diff(_val.value, _val.hvw_value, _val.override);
                HTML += "<td>" + _val.value.join(", ") + (arr_diff && arr_diff.length > 0 ? (_val.value.length == 0 ? "" : ", ") + arr_diff.join("<sup class='_redp'>HV</sup>, ") + "<sup class='_redp'>HV</sup>" : "") + "</td> ";
                HTML += "</tr> ";
              }
            } catch (err) {
              _iterator11.e(err);
            } finally {
              _iterator11.f();
            }
            HTML += "</tbody> ";
            HTML += "</table> ";
            HTML += "</div>";
          }
          /********************* TEXT *****************************/
          if (itm.type == "text") {
            HTML += "<div class='hvwwarner'> <sup>HV</sup>: Von Hausverwaltung befüllt </div>";
            if (itm.values[0].value) {
              HTML += '<div class="mainblock list"> <h4>' + itm.title + "</h4>";
              HTML += "<table> ";
              HTML += "<tbody> ";
              var _iterator12 = _createForOfIteratorHelper(itm.values),
                _step12;
              try {
                for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
                  var _val2 = _step12.value;
                  HTML += "<tr> ";
                  HTML += "<td>" + (Array.isArray(_val2.value) ? _val2.value.join(", ") : _val2.value) + "</td> ";
                  HTML += "</tr> ";
                }
              } catch (err) {
                _iterator12.e(err);
              } finally {
                _iterator12.f();
              }
              HTML += "</tbody> ";
              HTML += "</table> ";
              HTML += "</div>";
            }
          }
        }
      }
      return HTML;
    },
    make_excel_Pdf: function make_excel_Pdf(item, buildingsItem) {
      var isVariant1 = item.variant == 'v1';
      var isVariant2 = item.variant == 'v2';
      var isVariant3 = item.variant == 'v3';
      var HTML = '';
      HTML += '<div class="pagebreak"></div>';
      HTML += '<div class="mainblock excel"> <h3>' + item.title + "</h3> ";
      var ffloors = item.table.floors;
      for (var bid in ffloors) {
        HTML += '<div class="nobreak">';
        HTML += "<h5>" + ((buildingsItem.buildings[bid][0].values[0] || {}).value || 'Gebäude') + " </h5>";
        HTML += "<table>";
        HTML += "<thead><tr>";
        if (isVariant1) {
          HTML += "<th class='tal'>Bauteil / Geschoss </th>";
          HTML += "<th class='tar'>BGF</th>";
          HTML += "<th class='tar'>Höhe</th>";
          HTML += "<th class='tar'>Umbauter Raum</th>";
          HTML += "<th class='tar'>EHW</th>";
          HTML += "<th class='tar'>NBW</th>";
        } else if (isVariant2) {
          HTML += "<th class='tal'>Bauteil / Geschoss </th>";
          HTML += "<th class='tar'>WNFL</th>";
          HTML += "<th class='tar'>EHW</th>";
          HTML += "<th class='tar'>NBW</th>";
        } else if (isVariant3) {
          HTML += "<th class='tal'>Bauteil / Geschoss </th>";
          HTML += "<th class='tar'> </th>";
          HTML += "<th class='tar'>NBW</th>";
        }
        HTML += "</tr></thead>";
        HTML += "<tbody>";
        var _iterator13 = _createForOfIteratorHelper(item.table.floors[bid]),
          _step13;
        try {
          for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
            var f = _step13.value;
            HTML += "<tr>";
            if (isVariant1) {
              HTML += "<td>" + f.name + "</td>";
              HTML += "<td class='tar'>" + this.$_roundMoney(f.area, 2) + "<span>&nbsp;m<sup>2</sup></span></td>";
              HTML += "<td class='tar'>" + this.$_roundMoney(f.height, 2) + "<span>&nbsp;m</span></td>";
              HTML += "<td class='tar'>" + this.$_roundMoney(f.area * f.height, 2) + "<span>&nbsp;m<sup>3</sup></span></td>";
              HTML += "<td class='tar'>€&nbsp;" + this.$_roundMoney(f.ehw, 2) + " </td>";
              HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(f.ehw * f.area * f.height, -3) + "</td>";
            } else if (isVariant2) {
              HTML += "<td>" + f.name + "</td>";
              HTML += "<td class='tar'>" + this.$_roundMoney(f.area, 2) + "<span>&nbsp;m<sup>2</sup></span></td>";
              HTML += "<td class='tar'>€&nbsp;" + this.$_roundMoney(f.ehw, 2) + " </td>";
              HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(f.ehw * f.area, -3) + "</td>";
            } else if (isVariant3) {
              HTML += "<td>" + f.name + "</td>";
              HTML += "<td class='tar'>" + (f.freetext || '') + "</td>";
              HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(f.freecost || 0, -3) + "</td>";
            }
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator13.e(err);
        } finally {
          _iterator13.f();
        }
        HTML += "</tbody>";
        HTML += "<tfoot class='bold'><tr>";
        if (isVariant1) {
          HTML += "<td></td>";
          HTML += "<td class='tar'>" + this.$_roundMoney(item.table.allFloorsData[bid].area, 2) + "<span>&nbsp;m<sup>2</sup></span></td>";
          HTML += "<td></td>";
          HTML += "<td class='tar'>" + this.$_roundMoney(item.table.allFloorsData[bid].vol, 2) + "<span>&nbsp;m<sup>3</sup></span></td>";
          HTML += "<td></td>";
          HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(item.table.allFloorsData[bid].cost, -3) + "</td>";
        } else if (isVariant2) {
          HTML += "<td></td>";
          HTML += "<td class='tar'>" + this.$_roundMoney(item.table.allFloorsData[bid].area, 2) + "<span>&nbsp;m<sup>2</sup></span></td>";
          HTML += "<td></td>";
          HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(item.table.allFloorsData[bid].cost, -3) + "</td>";
        } else if (isVariant3) {
          HTML += "<td></td>";
          HTML += "<td></td>";
          HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(item.table.allFloorsData[bid].cost, -3) + "</td>";
        }
        HTML += "</tr></tfoot>";
        HTML += "</table>";
        HTML += "</div>"; // .nobreak
      }
      HTML += '<div class="nobreak">';
      if (Object.values(item.table.misc).length > 0) {
        HTML += "<table>";
        HTML += "<thead><tr>";
        HTML += "<th class='tal'>Bezeichnung</th>";
        HTML += "<th class='tar'>NBW</th>";
        HTML += "</tr></thead>";
        HTML += "<tbody>";
        var _iterator14 = _createForOfIteratorHelper(item.table.misc),
          _step14;
        try {
          for (_iterator14.s(); !(_step14 = _iterator14.n()).done;) {
            var m = _step14.value;
            HTML += "<tr>";
            HTML += "<td>" + m.name + "</td>";
            HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(m.cost, -3) + "</td>";
            HTML += "</tr>";
          }
        } catch (err) {
          _iterator14.e(err);
        } finally {
          _iterator14.f();
        }
        HTML += "</tbody>";
        HTML += "<tfoot class='bold'><tr>";
        HTML += "<td></td>";
        HTML += "<td class='tar'>€&nbsp;" + this.$_ceilMoney(item.table.summisc, -3) + "</td>";
        HTML += "</tr></tfoot>";
        HTML += "</table>";
      }
      HTML += "</div>"; // nobreak
      // HTML += "<hr />"
      HTML += "<div class='vert-line'>";
      HTML += '<div class="subtext">inkl. Planungs- und Konstruktionskosten exkl. Steuer </div>';
      HTML += '<div class="subtext">Alle Werte gerundet und ohne Mehrwertsteuer angegeben. </div>';
      HTML += '<div class="endvalue pad">  Gesamt: €&nbsp;' + this.$_ceilMoney(item.table.total.cost, -3) + " </div> ";
      HTML += " </div>";
      HTML += " </div>";
      return HTML;
    },
    add_allimages_Pdf: function add_allimages_Pdf(imageTree) {
      var HTML = '';
      if (imageTree) {
        HTML += '<div class="pagebreak"></div>';
        HTML += "<div><ul>";
        var allimgs = [];
        for (var imgg in imageTree) {
          // window.LOG.log(imageTree[imgg]);
          if (imageTree[imgg].name.indexOf('.webp') > 0 && !allimgs.includes(imageTree[imgg].name.toLowerCase())) {
            allimgs.push(imageTree[imgg].name.toLowerCase());
            var imgpath = "'" + ($env.mainurl + imageTree[imgg].path) + "'";
            HTML += ' <li class="li_mapimage smaller2" style="background-image:url(' + imgpath + ')"></li>';
          }
        }
        HTML += "</ul></div>";
      }
      return HTML;
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card mappwien"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingMap = !_vm.isShowingMap;
      }
    }
  }, [_vm._v("Karten Links")]), _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "bold _greenp"
  }, [_vm._v(_vm._s(_vm.address))])])]), _vm.isShowingMap && _vm.allianzData ? _c('div', {
    staticClass: "card-content"
  }, [_c('h3', [_vm._v("Dynamische Links ")]), _c('small', [_vm._v("(Adresse wird automatisch übernommen)")]), _c('h5', [_vm._v("Allgemein")]), _c('ul', [_vm.loc ? _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://www.google.com/maps/dir/' + (_vm.loc.lat + ',' + _vm.loc.lng) + '/' + (_vm.loc.lat + ',' + _vm.loc.lng) + '/@' + (_vm.loc.lat - 0.0015 + ',' + _vm.loc.lng) + ',186a,35y,360h,45t/data=!3m1!1e3!4m2!4m1!3e2',
      "target": "_blank"
    }
  }, [_vm._v("Google maps 3D-KARTEN Link")])]) : _vm._e()]), _c('h5', [_vm._v("WIEN")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://www.wien.gv.at/flaechenwidmung/public/start.aspx?lang=de-AT&bookmark=YR3hQ0RdHESqeP1GnTieRm3-cqJIRKZKu9yGScYR7deS62B2b3Jy0Jw-b-b&bmadr=' + _vm.address,
      "target": "_blank"
    }
  }, [_vm._v("Flächenwidmungsplan für Wien")])])]), _c('br'), _c('hr'), _c('br'), _c('h3', [_vm._v("Einfache Links ")]), _c('small', [_vm._v("(Adresse muss manuel eingegeben werden)")]), _c('div', {
    staticClass: "kartenlinks"
  }, [_c('h5', [_vm._v("Allgemein")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": _vm.bingMapLink,
      "target": "_blank"
    }
  }, [_vm._v("BING MAP")])])]), _c('h5', [_vm._v("Oberösterreich ATLAS GIS")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.doris.at/viewer/(S(acv50rj3e4dfiz55v4i5jp45))/init.aspx?ks=alk&karte=dkm',
      "target": "_blank"
    }
  }, [_vm._v("www.doris.at")])])]), _c('h5', [_vm._v("Blasl")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.doris.at/viewer/(S(2spfne45fctjimjxxfz5ao45))/init.aspx?karte=flaewi&ks=alk&redliningid=acv50rj3e4dfiz55v4i5jp45&box=-70386.0701107011%3b256542%3b151468.070110701%3b403901&srs=31255',
      "target": "_blank"
    }
  }, [_vm._v("www.doris.at")])])]), _c('h5', [_vm._v("Kartenmaterial Österreich")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://www.geoland.at/',
      "target": "_blank"
    }
  }, [_vm._v("geoland.at")])]), _vm._m(0)]), _c('h5', [_vm._v("Niederösterreich")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.intermap1.noel.gv.at/webgisatlas/(S(n5rwbvnhlqs4lz3uqutwigiu))/init.aspx?karte=atlas_gst&ks=planung_und_kataster&redliningid=40fubl55goxmbg55tyzrmk55&box=652196.195833336%3b317046.850218116%3b652406.804166669%3b317164.58980145&srs=31259',
      "target": "_blank"
    }
  }, [_vm._v("intermap1.noel.gv.at")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://atlas.noe.gv.at/webgisatlas/(S(w3epxnup1uil0ofxpevioofe))/init.aspx?karte=atlas_gst',
      "target": "_blank"
    }
  }, [_vm._v("atlas.noe.gv.at")])])]), _c('h5', [_vm._v("WIEN")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.wien.gv.at/flaechenwidmung/public/',
      "target": "_blank"
    }
  }, [_vm._v("wien.gv.at Flächenwidmungsplan")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.wien.gv.at/stadtplan/',
      "target": "_blank"
    }
  }, [_vm._v("wien.gv.at Stadtplan")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.kanis.at/KANIS/synserver?project=KANIS_wma&user=guest&client=html',
      "target": "_blank"
    }
  }, [_vm._v("KANIS Karte")])])]), _c('h5', [_vm._v("SALZBURG")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://www.salzburg.gv.at/sagisonline/(S(sb5uwyhbcbmho4ghsxfqtmoe))/init.aspx?karte=basis&geojuhuschema=Adressen/Namensgut&defaultlogo=sagis&gdiservices=lubi&sichtbar=Luftbilddatenbank%20Verf%C3%BCgbarkeit',
      "target": "_blank"
    }
  }, [_vm._v("salzburg.gv.at")])])]), _c('h5', [_vm._v("BURGENLAND")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'http://www.doris.at/viewer/(S(acv50rj3e4dfiz55v4i5jp45))/init.aspx?ks=alk&karte=dkm',
      "target": "_blank"
    }
  }, [_vm._v("www.doris.at")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://geodaten.bgld.gv.at/de/kartendienste-fachdaten/oeffentliche-kartendienste.html',
      "target": "_blank"
    }
  }, [_vm._v("Geodaten Burgenland Kartendienste")])])]), _c('h5', [_vm._v("KÄRNTEN")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://gis.ktn.gv.at/atlas/(S(z5gp4pm1eqmw1xieuwsz3vph))/init.aspx?karte=atlas_basiskarten',
      "target": "_blank"
    }
  }, [_vm._v("gis.ktn.gv.at (alt)")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://gis.ktn.gv.at/webgisviewer/atlas-mobile/map/Basiskarten/0rientierung%20u.%20Kataster ',
      "target": "_blank"
    }
  }, [_vm._v("Kataster")])])]), _c('h5', [_vm._v("STEIERMARK")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://gis2.stmk.gv.at/atlas/(S(0kd3atgozu2hri5oxht4icfw))/init.aspx?karte=kat&ks=das&cms=da',
      "target": "_blank"
    }
  }, [_vm._v("gis2.stmk.gv.at")])])]), _c('h5', [_vm._v("TIROL")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": 'https://maps.tirol.gv.at/tirisMaps/s',
      "target": "_blank"
    }
  }, [_vm._v("maps.tirol.gv.at - tirisMaps")])])])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "https://www.basemap.at/application/#{\"center\":[1445590,6059660],\"zoom\":8,\"rotation\":0,\"layers\":\"10000000\"}",
      "target": "_blank"
    }
  }, [_vm._v("basemap.at")])]);
}];
export { render, staticRenderFns };
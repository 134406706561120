import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dateien"
  }, [_c('span', [_vm.$_size(_vm.filesNotUploadedYet) > 0 ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('p', [_vm._v("Folgende Dateien sind offline gespeichert: (Vorschau für Offline-Bilder deaktiviert aufgrund von zu langen Ladezeiten)")]), _c('div', {
    staticClass: "list-offline-files"
  }, _vm._l(_vm.filesNotUploadedYet, function (file) {
    return _c('div', {
      staticClass: "list-offline-files-item"
    }, [_c('div', {
      staticClass: "offline-image-file",
      style: {
        background: '222'
      }
    }, [_c('a', {
      staticClass: "btn _small _green",
      attrs: {
        "href": _vm.$_makeFileFromBlob(file.data),
        "download": file.filename,
        "target": "_blank"
      }
    }, [_vm._v("Download")]), _c('div', {
      staticClass: "clear"
    }), _c('small', [_vm._v(_vm._s(file.filename))])])]);
  }), 0), _c('div', {
    staticClass: "clear"
  }), _vm.isInternet ? _c('span', [_c('div', {
    staticClass: "btn _medium _green",
    on: {
      "click": _vm.UploadeNotReadyFiels
    }
  }, [_vm._v(_vm._s(_vm.filesNotUploadedYet.length) + " Offline Datei(en) hochladen")])]) : !_vm.isInternet ? _c('span', {
    staticClass: "bold _redp"
  }, [_vm._v("Bei Internet Verbindung könenn diese Daten später hochgeladen werden!")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "card _less"
  }, [_c('div', {
    staticClass: "fr btn _small",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('PhotoUpload');
      }
    }
  }, [_vm._v("Camera")]), _c('div', {
    staticClass: "clear"
  }), _vm.$_size(_vm.filesFromSp) > 0 || _vm.totalFileSize > 0 ? _c('div', {
    staticClass: "padding tac bold"
  }, [_c('div', {
    staticClass: "padding"
  }, [_vm._v("Es befinden sich " + _vm._s(_vm.$_size(_vm.filesFromSp)) + " Dateien im Archiv. Das Archiv ist " + _vm._s(_vm.$_toHumanFileSize(_vm.totalFileSize)) + " groß. Bitte um etwas Geduld beim Download!")]), _c('div', {
    staticClass: "btn _medium _green",
    on: {
      "click": function click($event) {
        !_vm.isCompressing && _vm.restoreFilesFromSp();
      }
    }
  }, [_vm._v("Daten wiederherstellen")])]) : _vm._e()]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.showFiles = !_vm.showFiles;
      }
    }
  }, [_vm._v("Dateien")])]), _vm.isPreloadingFiles ? _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm._e()]), _vm.showFiles ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm.view == 'tree' ? _c('div', {
    staticClass: "wz-tabs"
  }, [_c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.view_LOC == 'tree'
    },
    on: {
      "click": function click($event) {
        _vm.view_LOC = "tree";
      }
    }
  }, [_vm._v("Baum-Ansicht")]), _c('div', {
    staticClass: "wz-tab",
    on: {
      "click": function click($event) {
        return _vm.fetchSpFolderContent(_vm.currentProjectUid, "");
      }
    }
  }, [_vm._v("Neu Laden")]), _vm.folderContent.rootcontent ? _c('div', {
    staticClass: "fr wz-tab"
  }, [_c('ZipDownFolder', {
    attrs: {
      "folderName": _vm.folderContent.rootcontent.name,
      "folderId": _vm.folderContent.rootcontent.id,
      "currendId": _vm.currentProjectUid
    }
  })], 1) : _vm._e()]) : _vm._e()]), _vm.view == 'files' || _vm.view_LOC == 'files' ? _c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "clear"
  }), _vm.showEmbedImage ? _c('ul', {
    staticClass: "files ovauto"
  }, [_vm._l(_vm.imagesDir, function (item, idx) {
    return _vm.imagesDir.length > 0 && _vm.restrictFiles(item, true) ? _c('li', [_c('div', {
      staticClass: "link",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.OpenThisFile(item, idx);
        }
      }
    }, [_c('div', {
      staticClass: "embimg _small",
      style: 'background-image:url("' + _vm.$_getImgUrl(item.path, 't') + '")'
    }), _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.name))])])]) : _vm._e();
  }), _vm.imagesDir.length == 0 ? _c('li', [_vm._v("keine Bilder vorhanden!")]) : _vm._e()], 2) : _vm._e()]) : _vm.view_LOC == 'tree' ? _c('div', {
    staticClass: "ovauto2"
  }, [!_vm.isInternet ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h4', {
    staticClass: "color-red"
  }, [_vm._v("Keine Internet Verbindung")]), _c('p', {
    staticClass: "color-red"
  }, [_vm._v("Daten können nicht angezeigt werden!")]), _c('p', [_vm._v("jedoch können Bilder offline gespeichert werden.")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('PhotoUpload');
      }
    }
  }, [_vm._v("Bilder offline speichern")])]) : _vm._e(), _vm.isPreloadingFiles && (_vm.folderContent.allcontent || []).length === 0 ? _c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]) : _vm._e(), _c('ul', {
    staticClass: "treeview"
  }, _vm._l(_vm.folderContent.content, function (folder, folderName) {
    return _c('li', {
      staticClass: "nomarg"
    }, [_c('onefolderAllianz', {
      attrs: {
        "folder": _vm.getFolderContent(folderName),
        "spToken": _vm.folderContent.spToken,
        "currendId": _vm.currentProjectUid
      }
    }), _c('ul', _vm._l(folder, function (item, fIdx) {
      return _c('li', [_c('onefileAllianz', {
        attrs: {
          "item": item,
          "spToken": _vm.folderContent.spToken,
          "folder": folderName,
          "index": fIdx
        },
        on: {
          "updateFetch": function updateFetch($event) {
            return _vm.fetchSpFolderContent(_vm.currentProjectUid, "");
          }
        }
      })], 1);
    }), 0)], 1);
  }), 0)]) : _vm._e()]) : _vm._e()]), _vm._m(1)]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "FilesToUpload",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenFielsToUpload
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesToUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_c('h3', [_vm._v("Datei hochladen")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ordner auswählen:")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.toUploadFolder,
      expression: "toUploadFolder"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.toUploadFolder = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.folderInProject, function (folder) {
    return _c('option', [_vm._v(_vm._s(folder))]);
  }), 0)]), _vm.toUploadFolder == 'Bilder/' ? _c('div', {
    staticClass: "padding"
  }, [_c('small', [_vm._v("* Für den 'Bilder' Ordner ausschließlich .jpg/.jpeg/.JPG oder .png Dateien benutzen. Andere Ordner können auch andere Bildformate beeinhalten!")])]) : _vm._e(), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Dateien")]), _c('input', {
    attrs: {
      "id": "avatar",
      "type": "file",
      "name": "spfiles",
      "multiple": "",
      "capture": ""
    },
    on: {
      "change": _vm.addFiles
    }
  })]), _vm.touploadfiles.length > 0 ? _c('span', [_c('h4', [_vm._v("Folgende Dateien werden raufgeladen:")]), _c('ul', {
    staticClass: "touploadfiles"
  }, _vm._l(_vm.touploadfiles, function (file) {
    return _c('li', [_c('div', {
      staticClass: "filenamename"
    }, [_c('i', {
      staticClass: "icon-file"
    }), _c('span', [_vm._v("  " + _vm._s(file.name) + " (" + _vm._s(_vm.$_toHumanFileSize(file.size, true)) + ")")])])]);
  }), 0)]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesToUpload');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.SendFiles();
      }
    }
  }, [_vm._v("Daten hochladen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "FilesOfflineToUpload",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesOfflineToUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_vm.$_size(_vm.filesNotUploadedYet) > 0 ? _c('div', {
    staticClass: "padding"
  }, [_c('h5', [_vm._v("Offline gespeicherte Daten:")]), _c('div', {
    staticClass: "padding"
  }, [_c('small', [_vm._v("* Bitte für den 'Bilder' Ordner ausschließlich .jpg/.jpeg/.JPG oder .png Dateien benutzen. Andere Ordner können auch andere Bildformate beeinhalten!")])]), _c('ul', {
    staticClass: "touploadfiles"
  }, _vm._l(_vm.filesNotUploadedYet, function (file, fileIdx) {
    return _c('li', [_c('v-checkbox', {
      attrs: {
        "item": fileIdx,
        "label": file.folder + '/' + fileIdx
      },
      model: {
        value: _vm.toUploadOflineFiles,
        callback: function callback($$v) {
          _vm.toUploadOflineFiles = $$v;
        },
        expression: "toUploadOflineFiles"
      }
    }), _c('div', {
      staticClass: "fr"
    }, [_c('a', {
      staticClass: "link",
      attrs: {
        "href": _vm.$_makeFileFromBlob(file.data),
        "target": "_blank",
        "download": file.filename
      }
    }, [_vm._v("DOWNLOAD")]), _c('div', {
      staticClass: "btn _small _red",
      on: {
        "click": function click($event) {
          return _vm.askCleanUpIDB([fileIdx]);
        }
      }
    }, [_vm._v("Löschen")])])], 1);
  }), 0)]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesOfflineToUpload');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.sendOfflineFiles();
      }
    }
  }, [_vm._v("Offline Daten hochladen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "PhotoUpload",
      "adaptive": true,
      "draggable": false,
      "resizable": true,
      "reset": true,
      "width": "480",
      "height": "240"
    },
    on: {
      "before-open": _vm.beforeOpenPhotoUpload
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("PHOTO Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('PhotoUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "folder bold"
  }, [_vm._v("Hochladen in : " + _vm._s(_vm.toUploadFolder))]), _c('input', {
    attrs: {
      "id": "allianz-auto-image",
      "type": "file",
      "accept": "image/*",
      "capture": ""
    },
    on: {
      "change": _vm.addPhotos
    }
  }), _c('div', {
    staticClass: "spacer _vertical _large"
  }), _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('PhotoUpload');
      }
    }
  }, [_vm._v("Abbrechen")])])]) : _vm._e()]), _c('modal', {
    staticClass: "modal",
    attrs: {
      "id": "FilePreview",
      "name": "FilePreview",
      "escapeKeyToClose": true,
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    },
    on: {
      "before-open": _vm.beforeOpenPreview
    }
  }, [_vm.previewFile ? _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "Clicker left",
    on: {
      "click": function click($event) {
        _vm.previewFileIDX--;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  })]), _c('div', {
    staticClass: "Clicker right",
    on: {
      "click": function click($event) {
        _vm.previewFileIDX++;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-right"
  })]), ['webp', 'jpg', 'jpeg', 'png'].includes(_vm.$_extension(_vm.previewFile.name)) ? _c('div', {
    staticClass: "embeded"
  }, [_c('Printer', {
    attrs: {
      "showimg": _vm.$_makeImgThumbnailUrl(_vm.previewFile, 3200, _vm.folderContent.spToken),
      "preview": _vm.$_makeImgThumbnailUrl(_vm.previewFile, 560, _vm.folderContent.spToken)
    }
  })], 1) : ['pdf', 'docx', 'xlsx', 'txt'].includes(_vm.$_extension(_vm.previewFile.name)) ? _c('div', {
    staticClass: "embeded"
  }, [_c('iframe', {
    attrs: {
      "srcdoc": _vm.previeDocFileResp,
      "width": "100%",
      "height": "100%",
      "frameborder": "0"
    }
  })]) : _vm._e()]), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilePreview');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]) : _vm._e()]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "FileRenamerAllianz",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenRenameAllianzFileModal
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Datei umbenennen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FileRenamerAllianz');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h3', [_vm._v("Datei umbenennen"), _c('i', {
    staticClass: "icon-label"
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ordner:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.toRenameFile.path))])]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Dateiname:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.toRenameFile.name))])]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Neuer Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.toRenameFile.newName,
      expression: "toRenameFile.newName"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.toRenameFile.newName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.toRenameFile, "newName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FileRenamerAllianz');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        !_vm.preloaderforfile && _vm.renameFile();
      }
    }
  }, [_vm._v("Datei umbenennen")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Offline Speicher")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "visibility": "hidden",
      "opacity": "0"
    },
    attrs: {
      "id": "drop-zone"
    }
  }, [_c('div', {
    attrs: {
      "id": "text-node"
    }
  }, [_c('h1', [_c('i', {
    staticClass: "icon-upload"
  })]), _vm._v("HIER Daten ablegen!")])]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.regexp.flags.js";
var render = function render(_c, _vm) {
  return _c('div', {
    staticClass: "StatusLights"
  }, [_vm.props.item ? _c('span', {
    attrs: {
      "set": _vm.item = _vm.props.item
    }
  }, [_c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _red: _vm.item.isOpen || (_vm.item.flags || {}).isOpen
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _brown: _vm.item.isSentEmail || (_vm.item.flags || {}).isSentEmail
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _orange: _vm.item.isFilledByHv || (_vm.item.flags || {}).isFilledByHv
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _yellow: _vm.item.besichtigungsTermin
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _lblue: _vm.item.svuid && _vm.item.svuid.length > 1 && _vm.item.svuid != '000' && _vm.item.svuid != 'AvWqcX0ILtLSbxAWmr6C'
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _green: _vm.item.isFilledBySv || (_vm.item.flags || {}).isFilledBySv
    }
  }), _c('div', {
    staticClass: "wz-tag cirlce _lgrey",
    class: {
      _darkpink: _vm.item.isCompleted || (_vm.item.flags || {}).isCompleted
    }
  })]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "sidekick"
  }, [_c('ul', {
    staticClass: "ul"
  }, _vm._l(_vm.sortedList, function (item) {
    return _c('li', {
      staticClass: "li",
      class: {
        _sel: _vm.aktUid == item.id,
        vncheck: item.hasReportData && !item.isVnSame,
        polcheck: item.hasReportData && !item.isPolNrSame
      }
    }, [_c('router-link', {
      attrs: {
        "to": "/allianz/list/" + item.id
      }
    }, [_c('div', [_c('div', {
      staticClass: "bold color-darkgrey"
    }, [_vm._v(_vm._s(item.adresse_risiko) + ", " + _vm._s(item.plz_risiko) + " " + _vm._s(item.ortschaft_risiko) + " ")]), _c('small', {
      staticClass: "color-grey"
    }, [_vm._v(_vm._s(item.vorname) + " " + _vm._s(item.name))]), _c('br'), _c('small', {
      staticClass: "color-grey"
    }, [_vm._v(_vm._s(item.polnr)), _c('div', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(_vm.$dayjs(item.modified).format(_vm.MAIN_DATE_FORMAT_CONDENSED)))])])])])], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters, mapState, mapActions } from "vuex";
var self = null;
export default {
  name: "AlianzDocumentHead",
  beforeCreate: function beforeCreate() {
    self = this;
  },
  components: {},
  data: function data() {
    return {};
  },
  props: ["item", "value"],
  model: {
    prop: "item",
    event: "UpdateEvent"
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState([])), mapGetters([])), {}, {
    // wnfl: () => self.item.wnfl,
    // excelEigen: () => self.item.excelEigen,

    isVariant1: function isVariant1() {
      return self.item.variant == "v1";
    },
    isVariant2: function isVariant2() {
      return self.item.variant == "v2";
    },
    isVariant3: function isVariant3() {
      return self.item.variant == "v3";
    }
  })
};
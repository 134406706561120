import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card AllianzAktSvStatus"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingContacts = !_vm.isShowingContacts;
      }
    }
  }, [_vm._v("Kontakte")])]), _vm.isShowingContacts ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "input-table"
  }, [_c('div', {
    staticClass: "input-table-label uppercase"
  }, [_vm._v("Hausverwaltung")]), _c('div', {
    staticClass: "input-table-item _large"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.$_O2A(_vm._fbHausverwaltungen),
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    model: {
      value: _vm.hvUid,
      callback: function callback($$v) {
        _vm.hvUid = $$v;
      },
      expression: "hvUid"
    }
  })], 1), _c('div', {
    staticClass: "input-table-item _small"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm._fbHausverwaltungen[_vm.hvUid] || {}).email,
      expression: "(_fbHausverwaltungen[hvUid]||{}).email"
    }],
    attrs: {
      "type": "text",
      "disabled": "",
      "placeholder": "Email"
    },
    domProps: {
      "value": (_vm._fbHausverwaltungen[_vm.hvUid] || {}).email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm._fbHausverwaltungen[_vm.hvUid] || {}, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-table-item _small"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm._fbHausverwaltungen[_vm.hvUid] || {}).phone,
      expression: "(_fbHausverwaltungen[hvUid]||{}).phone"
    }],
    attrs: {
      "type": "text",
      "disabled": "",
      "placeholder": "Telefon"
    },
    domProps: {
      "value": (_vm._fbHausverwaltungen[_vm.hvUid] || {}).phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm._fbHausverwaltungen[_vm.hvUid] || {}, "phone", $event.target.value);
      }
    }
  })])]), _vm._l(_vm.contactData, function (con, idx) {
    return _c('div', {
      staticClass: "input-table"
    }, [_c('div', {
      staticClass: "input-table-label uppercase"
    }, [_vm._v(_vm._s(idx == 'eigen' ? 'Eigentümer' : idx))]), _c('div', {
      staticClass: "input-table-item _large"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.contactData[idx].name,
        expression: "contactData[idx].name"
      }],
      attrs: {
        "type": "text",
        "placeholder": "Name"
      },
      domProps: {
        "value": _vm.contactData[idx].name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.contactData[idx], "name", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "input-table-item _small"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.contactData[idx].email,
        expression: "contactData[idx].email"
      }],
      attrs: {
        "type": "text",
        "placeholder": "Email"
      },
      domProps: {
        "value": _vm.contactData[idx].email
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.contactData[idx], "email", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "input-table-item _small"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.contactData[idx].phone,
        expression: "contactData[idx].phone"
      }],
      attrs: {
        "type": "text",
        "placeholder": "Telefon"
      },
      domProps: {
        "value": _vm.contactData[idx].phone
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.contactData[idx], "phone", $event.target.value);
        }
      }
    })])]);
  }), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" Kontakt erstellen: ")]), _c('div', {
    staticClass: "btn _blue _small",
    on: {
      "click": function click($event) {
        return _vm.AddContact(false, "makler");
      }
    }
  }, [_vm._v("Makler")]), _c('div', {
    staticClass: "btn _blue _small",
    on: {
      "click": function click($event) {
        return _vm.AddContact(false, "eigen");
      }
    }
  }, [_vm._v("Eigentümer")]), _c('div', {
    staticClass: "btn _blue _small",
    on: {
      "click": function click($event) {
        return _vm.AddContact(false, "besichtigung");
      }
    }
  }, [_vm._v("Besichtigung")])], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import { mapGetters, mapState, mapActions } from "vuex";
import { setImmediate } from "timers";
var vu = null;
export default {
  name: "excel",
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  components: {},
  data: function data() {
    return {
      timer: null,
      // ehw: Nameit([250, 400, 500, 550, 600]),
      floors: [],
      misc: [{
        name: "Außenanlagen",
        cost: 0
      }],
      total: {
        name: "Gesamt",
        cost: 0
      }
    };
  },
  props: ["item", "value", "excelid", "binfo"],
  model: {
    prop: "item",
    event: "UpdateEvent"
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler: function handler(nv, ov) {
        vu.Starter();
      }
    },
    binfo: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        vu.Starter();
        vu.floorChanger();
        vu.recalc();
      }
    },
    allFloorsData: {
      deep: true,
      immediate: true,
      handler: function handler() {
        vu.recalc();
      }
    },
    misc: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        // window.LOG.orange("nv", nv);
        vu.recalc();
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState([])), mapGetters([])), {}, {
    // posts: () => vu.$store.state.posts
    allFloorsData: function allFloorsData() {
      // window.LOG.red("allFloorsData");
      var binfo = vu.binfo;
      var result = [];
      for (var bid in binfo) {
        var floors = binfo[bid][5].floors;
        var obj = {
          area: 0,
          vol: 0,
          cost: 0
        };
        for (var f in floors) {
          var area = floors[f].area || 0;
          var height = floors[f].height || 0;
          var ehw = floors[f].ehw || 0;
          obj.area += area;
          obj.vol += area * height;
          obj.cost += vu.ceilUp(area * height * ehw);
        }
        vu.binfo[bid][5].allFloorsData = obj;
        result.push(obj);
      }
      return result;
    },
    calcMisc: function calcMisc() {
      var misc = vu.misc;
      var cost = 0;
      for (var m in misc) {
        cost += misc[m].cost;
      }
      return cost;
    },
    floornames: function floornames() {
      var arr = [];
      for (var bid in vu.binfo) {
        arr.push([]);
        for (var fid in vu.binfo[bid][2].values) {
          if (vu.binfo[bid][2].values[fid].vals !== "geschosse_dach") {
            arr[bid].push(vu.binfo[bid][2].values[fid].name);
          }
        }
      }
      return arr;
    }
  }),
  methods: {
    // ...mapActions([]),
    floorChanger: function floorChanger() {
      // window.LOG.red("floorChanger");
      // window.LOG.red("floorChanger");
      var binfo = vu.binfo;
      // let removeStocks = [];
      var thisfloornames = [];
      for (var bid in binfo) {
        thisfloornames.push([]);
        var floors = vu.floors[bid];
        for (var f in floors) {
          thisfloornames[bid].push(floors[f].name);
        }
      }
      for (var _bid in binfo) {
        if (vu.floornames[_bid].length < thisfloornames[_bid].length) {
          vu.binfo[_bid][5].floors.splice(vu.binfo[_bid][5].floors.length - 1, 1);
          window.LOG.red(vu.binfo[_bid][5]);
        }
        if (vu.floornames[_bid].length > thisfloornames[_bid].length) {
          // window.LOG.log("refactor2");
          vu.binfo[_bid][5].floors.push({
            name: "...Geschoss...",
            area: 0,
            height: 0,
            vol: 0,
            ehw: 0,
            cost: 0
          });
        }
        if (vu.floornames[_bid].length == thisfloornames[_bid].length) {
          for (var fn in vu.floornames[_bid]) {
            // window.LOG.log(fn, removeStocks);
            var fnname = vu.floornames[_bid][fn];
            vu.binfo[_bid][5].floors[fn].name = fnname;
          }
        }
      }
    },
    Starter: function Starter() {
      vu.misc = vu.item.table.misc;
      vu.total = vu.item.table.total;
      var floors = [];
      for (var bid in vu.binfo) {
        floors.push(vu.binfo[bid][5].floors);
      }
      vu.floors = floors;
    },
    ceilUp: function ceilUp(val) {
      return Math.ceil(val / 1000) * 1000;
    },
    AddNewMisc: function AddNewMisc() {
      vu.misc.push({
        name: "..",
        cost: 0 // calced
      });
    },
    DeleteMisc: function DeleteMisc(i) {
      vu.misc.splice(i, 1);
    },
    recalc: function recalc() {
      // window.LOG.log("recalc");
      var miscost = 0;
      var floorcost = 0;
      var allfloorcost = 0;
      var floorarea = 0;
      var floorvol = 0;
      var floorname = "";
      var allfloorsdata = [];
      for (var m in vu.misc) {
        miscost += vu.ceilUp(vu.misc[m].cost);
      }
      for (var f in vu.allFloorsData) {
        allfloorcost += vu.ceilUp(vu.allFloorsData[f].cost);
        floorcost = vu.allFloorsData[f].cost;
        floorarea = vu.allFloorsData[f].area;
        floorvol = vu.allFloorsData[f].vol;
        floorname = (vu.binfo[f][0].values[0] || {}).value;
        allfloorsdata.push({
          cost: floorcost,
          area: floorarea,
          vol: floorvol,
          name: floorname
        });
      }
      var floors = vu.floors;
      vu.total.cost = allfloorcost + miscost;
      var result = {
        // floors: vu.floors,
        floors: floors,
        allFloorsData: allfloorsdata,
        misc: vu.misc,
        total: vu.total,
        summisc: miscost
      };
      this.$emit("UpdateExcel", result, vu.excelid);
    }
  }
};
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { mapGetters, mapState, mapActions } from "vuex";
import { setImmediate } from "timers";
var vu = null;
export default {
  name: "excel2",
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  components: {},
  data: function data() {
    return {
      timer: null,
      floors: [],
      misc: [{
        name: "Außenanlagen",
        cost: 0
      }],
      total: {
        name: "Gesamt",
        cost: 0
      }
    };
  },
  props: ["item", "value", "excelid", "binfo"],
  model: {
    prop: "item",
    event: "UpdateEvent"
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler: function handler(nv, ov) {
        vu.Starter();
      }
    },
    binfo: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        vu.Starter();
        vu.recalc();
      }
    },
    allFloorsData: {
      deep: true,
      immediate: true,
      handler: function handler() {
        vu.recalc();
      }
    },
    misc: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        // window.LOG.orange("nv", nv);
        vu.recalc();
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState([])), mapGetters([])), {}, {
    // posts: () => vu.$store.state.posts

    allFloorsData: function allFloorsData() {
      // window.LOG.red("allFloorsData");
      var binfo = vu.binfo;
      var result = [];
      for (var bid in binfo) {
        binfo[bid];
        var floors = binfo[bid][5].floors;
        var obj = {
          area: 0,
          vol: 0,
          cost: 0
        };
        for (var f in floors) {
          var area = floors[f].area || 0;
          var ehw = floors[f].ehw || 0;
          obj.area += area;
          obj.vol += area;
          obj.cost += vu.ceilUp(area * ehw);
        }
        vu.binfo[bid][5].allFloorsData = obj;
        result.push(obj);
      }
      return result;
    },
    calcMisc: function calcMisc() {
      var misc = vu.misc;
      var cost = 0;
      for (var m in misc) {
        cost += vu.ceilUp(misc[m].cost);
      }
      return cost;
    }
  }),
  methods: {
    Starter: function Starter() {
      vu.misc = vu.item.table.misc;
      vu.total = vu.item.table.total;
    },
    ceilUp: function ceilUp(val) {
      return Math.ceil(val / 1000) * 1000;
    },
    AddNewFloor: function AddNewFloor(bid) {
      vu.binfo[bid][5].floors.push({
        name: "XX geschoss",
        area: 0.0,
        height: 0,
        vol: 0,
        ehw: 250,
        cost: 0 // calced
      });
    },
    DeleteFloor: function DeleteFloor(bid, i) {
      vu.binfo[bid][5].floors.splice(i, 1);
    },
    AddNewMisc: function AddNewMisc() {
      vu.misc.push({
        name: "..",
        cost: 0 // calced
      });
    },
    DeleteMisc: function DeleteMisc(i) {
      vu.misc.splice(i, 1);
    },
    recalc: function recalc() {
      // console.log("recalc");
      var miscost = 0;
      var floorcost = 0;
      var allfloorcost = 0;
      var floorarea = 0;
      var floorvol = 0;
      var floorname = "";
      var allfloorsdata = [];
      var floors = [];
      for (var m in vu.misc) {
        miscost += vu.ceilUp(vu.misc[m].cost);
      }
      for (var f in vu.allFloorsData) {
        allfloorcost += vu.ceilUp(vu.allFloorsData[f].cost);
        floorcost = vu.allFloorsData[f].cost;
        floorarea = vu.allFloorsData[f].area;
        floorvol = vu.allFloorsData[f].vol;
        floorname = (vu.binfo[f][0].values[0] || {}).value;
        allfloorsdata.push({
          cost: floorcost,
          area: floorarea,
          vol: 0,
          name: floorname
        });
        floors.push(vu.binfo[f][5].floors);
      }
      vu.total.cost = allfloorcost + miscost;
      var result = {
        floors: floors,
        allFloorsData: allfloorsdata,
        misc: vu.misc,
        total: vu.total,
        summisc: miscost
      };
      this.$emit("UpdateExcel", result, vu.excelid);
    }
  }
};
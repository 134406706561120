import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "excel-table"
  }, [_c('h2', [_vm._v("Bewertung / Gebäudeneubauwert nach WNFL.")]), _vm._l(_vm.binfo, function (fl, bid) {
    return _c('span', [_c('div', {
      staticClass: "spacer _vertical _small"
    }), _c('h3', [_vm._v("Bez.: " + _vm._s((_vm.binfo[bid][0].values[0] || {}).value))]), _c('table', {
      staticClass: "excel"
    }, [_vm._m(0, true), _c('tbody', [_vm._l(_vm.binfo[bid][5].floors, function (f, i) {
      return _c('tr', [_c('td', {
        staticClass: "no-padding"
      }, [_c('div', {
        staticClass: "btn _s _clear",
        on: {
          "click": function click($event) {
            return _vm.DeleteFloor(bid, i);
          }
        }
      }, [_c('i', {
        staticClass: "icon-times _redp"
      })])]), _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.binfo[bid][5].floors[i].name,
          expression: "binfo[bid][5].floors[i].name"
        }],
        staticClass: "full",
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": _vm.binfo[bid][5].floors[i].name
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(_vm.binfo[bid][5].floors[i], "name", $event.target.value);
          }
        }
      })]), _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model.number",
          value: _vm.binfo[bid][5].floors[i].area,
          expression: "binfo[bid][5].floors[i].area",
          modifiers: {
            "number": true
          }
        }],
        attrs: {
          "type": "number"
        },
        domProps: {
          "value": _vm.binfo[bid][5].floors[i].area
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(_vm.binfo[bid][5].floors[i], "area", _vm._n($event.target.value));
          },
          "blur": function blur($event) {
            return _vm.$forceUpdate();
          }
        }
      }), _vm._m(1, true)]), _c('td', [_c('span', [_vm._v("€")]), _c('div', {
        staticClass: "inl"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model.number",
          value: _vm.binfo[bid][5].floors[i].ehw,
          expression: "binfo[bid][5].floors[i].ehw",
          modifiers: {
            "number": true
          }
        }],
        attrs: {
          "type": "number",
          "step": "1",
          "min": "0"
        },
        domProps: {
          "value": _vm.binfo[bid][5].floors[i].ehw
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(_vm.binfo[bid][5].floors[i], "ehw", _vm._n($event.target.value));
          },
          "blur": function blur($event) {
            return _vm.$forceUpdate();
          }
        }
      })])]), _c('td', {
        staticClass: "tarv"
      }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(f.ehw * f.area, -3)))])]);
    }), _c('tr', [_c('td'), _c('td', {
      attrs: {
        "colspan": "5 "
      }
    }, [_c('div', {
      staticClass: "btn _s _bordered",
      on: {
        "click": function click($event) {
          return _vm.AddNewFloor(bid);
        }
      }
    }, [_vm._v("Geschoss hinzufügen")])])])], 2), _c('tfoot', [_c('tr', {
      staticClass: "bold blue"
    }, [_c('td'), _c('td', [_vm._v("SUMME:")]), _c('td', {
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(_vm._s(_vm.$_roundMoney(_vm.allFloorsData[bid].area, 2)) + " m"), _c('sup', [_vm._v("2")])]), _c('td', {
      staticClass: "tarv"
    }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(_vm.allFloorsData[bid].cost, -3)))])])])])]);
  }), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('h3', [_vm._v("Sonstiges:")]), _c('table', {
    staticClass: "excel"
  }, [_vm._m(2), _c('tbody', [_vm._l(_vm.misc, function (m, i) {
    return _c('tr', [_c('td', {
      staticClass: "no-padding"
    }, [_c('div', {
      staticClass: "btn _s _clear",
      on: {
        "click": function click($event) {
          return _vm.DeleteMisc(i);
        }
      }
    }, [_c('i', {
      staticClass: "icon-times _redp"
    })])]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: m.name,
        expression: "m.name"
      }],
      staticClass: "full",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": m.name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(m, "name", $event.target.value);
        }
      }
    })]), _c('td', {
      staticClass: "tarv"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: m.cost,
        expression: "m.cost",
        modifiers: {
          "number": true
        }
      }],
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": m.cost
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(m, "cost", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })])]);
  }), _c('tr', [_c('td'), _c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    staticClass: "btn _s _bordered",
    on: {
      "click": function click($event) {
        return _vm.AddNewMisc();
      }
    }
  }, [_vm._v("Feld hinzufügen")])])])], 2), _c('tfoot', [_c('tr', {
    staticClass: "bold blue"
  }, [_c('td'), _c('td', [_vm._v("SUMME:")]), _c('td', {
    staticClass: "tarv"
  }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(_vm.calcMisc, -3)))])])])]), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('h3', [_vm._v("Gesamt:")]), _c('table', {
    staticClass: "excel"
  }, [_c('tbody', [_c('tr', {
    staticClass: "bold"
  }, [_c('td', [_vm._v(_vm._s(_vm.total.name) + "Bewertung / Gebäudeneubauwert")]), _c('td', {
    staticClass: "tarv"
  }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(_vm.total.cost, -3)))])])])]), _c('div', {
    staticClass: "spacer _vertical"
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "no-padding"
  }), _c('th', [_vm._v("Bauteil / Geschoss ")]), _c('th', [_vm._v("WnFl")]), _c('th', [_vm._v("EHW")]), _c('th', [_vm._v("Neubauwert")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_vm._v("m"), _c('sup', [_vm._v("2")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "no-padding"
  }), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "tarv"
  }, [_vm._v("Neubauwert")])])]);
}];
export { render, staticRenderFns };
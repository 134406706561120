import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "HVWDaten"
  }, [!_vm.isProjectDataExistent && !_vm.isHvDataExistent ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm.isProjectDataExistent && _vm.isHvDataExistent && _vm.$_size(_vm.hvDataFromStore) > 0 ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Hausverwaltung Daten")]), _c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "wz-tags fr tar"
  }, [_c('router-link', {
    attrs: {
      "target": "_blank",
      "to": "/akte/hvw/" + _vm.currentProjectUid
    }
  }, [_vm._v("link")])], 1), _vm.hvDataFromStore.modified ? _c('div', {
    staticClass: "wz-tags fr tar"
  }, [_vm._v("bearbeitet: " + _vm._s(_vm.$dayjs(_vm.hvDataFromStore.modified).format('DD. MMM. YYYY, HH:mm')) + "  ")]) : _vm._e()])]), _vm.$_size(_vm.hvDataFromStore) > 0 && _vm.hvDataFromStore.address ? _c('div', {
    staticClass: "card-content"
  }, [_c('h5', [_vm._v("Objekt Adresse (von HV geänderte)")]), ((_vm.hvDataFromStore || {}).address || {}).changed || false ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Strasse; PLZ; Stadt; Land:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.address.changed.street) + "; " + _vm._s(_vm.hvDataFromStore.address.changed.plz) + "; " + _vm._s(_vm.hvDataFromStore.address.changed.city) + "; " + _vm._s(_vm.hvDataFromStore.address.changed.land) + "; ")])]) : _vm._e(), _c('h5', [_vm._v("Zentraschlüssel")]), _vm.hvDataFromStore.zentral ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Zentralschlüssel vorhanden / Alternative")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.zentral.iskey) + " / " + _vm._s(_vm.hvDataFromStore.zentral.alternative))])]) : _vm._e(), _c('h5', [_vm._v("Objekt Informationenen")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Anzahl Wohneinheiten:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.wohneinheiten))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Renovierung:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.renovierung))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Objektart:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.objektart))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Baujahr:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.baujahr))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Denkmalschutz:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.denkmal))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Haustechnik:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.haustechnik))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Heizungsanlage:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.heizungsanlage))])]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Aussenanlagen:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.aussenanlagen))])]), _c('h5', [_vm._v("Dachbodenausbau")]), _vm.hvDataFromStore.dachbodenausbau ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Wurde durchgeführt?; WANN")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.dachbodenausbau.is) + "; " + _vm._s(_vm.hvDataFromStore.dachbodenausbau.when) + ";")])]) : _vm._e(), _c('h5', [_vm._v("Gewerbe")]), _vm.hvDataFromStore.gewerbe ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Ist Gewerbe?; Fläche in %; Nutzungsart")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.gewerbe.isgewerbe) + "; " + _vm._s(_vm.hvDataFromStore.gewerbe.area) + "; " + _vm._s(_vm.hvDataFromStore.gewerbe.nutzungsart) + ";")])]) : _vm._e(), _c('h5', [_vm._v("VN Adresse (von HV geänderte)")]), (_vm.hvDataFromStore.vnaddress || {}).changed ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("VN Name")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.vnaddress.changed.name))])]) : _vm._e(), (_vm.hvDataFromStore.vnaddress || {}).changed ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Strasse; PLZ; Stadt; Land:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.vnaddress.changed.street) + "; " + _vm._s(_vm.hvDataFromStore.vnaddress.changed.plz) + "; " + _vm._s(_vm.hvDataFromStore.vnaddress.changed.city) + "; " + _vm._s(_vm.hvDataFromStore.vnaddress.changed.land))])]) : _vm._e(), _c('h5', [_vm._v("Sonstiges")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Sonstige Infos:")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.hvDataFromStore.sonstiges))])])]) : _vm._e()]) : _vm.isProjectDataExistent && !_vm.isHvDataExistent ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('p', [_vm._v("Das erstellt den Datensatz für die Hausverwaltungen/Versicherungsnehmer um einen generierten Link an diese zu versenden.")]), _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.CreateHVWTemplate(_vm.currentProjectUid);
      }
    }
  }, [_vm._v("HVW Daten erstellen")])])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Hausverwaltung Daten")])]);
}];
export { render, staticRenderFns };
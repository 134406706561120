import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianz-document"
  }, [_c('div', {
    staticClass: "doc-wrapper"
  }, [_c('div', {
    staticClass: "doc-nav"
  }, [((_vm.currentProject || {}).flags || {}).isCustomPdf ? _c('div', {
    staticClass: "card"
  }) : _c('div', {
    staticClass: "card _less"
  }, [_c('div', {
    staticClass: "btn _small _blue",
    on: {
      "click": function click($event) {
        !false && _vm.MakeHTMLFromBericht();
      }
    }
  }, [_vm._v("PDF erstellen")]), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.$modal.show("ShowBericht");
      }
    }
  }, [_vm._v("PDF anzeigen")]), _c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        return _vm.askSetUpNewReport();
      }
    }
  }, [_vm._v("Bericht Vorlage neu erstellen")])])]), _c('div', {
    staticClass: "doc-content"
  }, [((_vm.currentProject || {}).flags || {}).isCustomPdf ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('p', [_vm._v("Das Pdf wurde mit einer anderen Software bearbeitet und direkt hochgeladen!")]), _c('p', [_vm._v("Für den Namen bitte nur folgenden verwenden:")]), _c('p', {
    staticClass: "bold"
  }, [_vm._v("Bewertung-" + _vm._s(((_vm.currentProject || {}).allianzData || {}).polnr) + ".pdf ")]), _c('p', [_vm._v("Falls diese Vorlage verwendet werden soll, dann bitte die Checkbox \"Eigenes PDF\" ausschalten!")]), _vm.currentProject.reportData ? _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VS Neu (manuell)")]), _c('money', {
    staticClass: "tal input",
    model: {
      value: _vm.currentProject.reportData.vsNew,
      callback: function callback($$v) {
        _vm.$set(_vm.currentProject.reportData, "vsNew", $$v);
      },
      expression: "currentProject.reportData.vsNew"
    }
  })], 1) : _vm._e()])]) : _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "ovauto full"
  }, [_c('div', {
    staticClass: "__AllianzInputs"
  }, [_vm._m(1), _c('div', {
    staticClass: "card-content"
  }, [_vm.Bericht.length > 0 ? _c('div', {
    attrs: {
      "id": "TheDocument"
    }
  }, _vm._l(_vm.Bericht, function (berichtItem, ix) {
    return _c('ul', {
      staticClass: "bericht"
    }, [berichtItem.type == 'head' ? _c('li', {
      staticClass: "MainBlock head",
      class: 'item_' + ix
    }, [_c('div', {
      staticClass: "blockfunctions bold"
    }, [_vm._v(_vm._s(berichtItem.title)), _c('small', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(berichtItem.type))])]), _vm._l(['left', 'right'], function (pos) {
      return _c('ul', {
        staticClass: "fields"
      }, _vm._l(berichtItem.values, function (it, idx) {
        return pos == it.pos ? _c('li', {
          key: idx + 'head-item'
        }, [it.type == 'select' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))])]), it.name == 'Denkmalschutz' ? _c('div', {
          staticClass: "list_value"
        }, [_c('div', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(_vm.isDs))]), _vm._m(2, true)]) : _c('div', {
          staticClass: "list_value"
        }, [_c('VueSelect', {
          attrs: {
            "label": "name",
            "taggable": "",
            "options": _vm.vals[it.vals],
            "reduce": function reduce(itm) {
              return itm.name;
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $$v);
            },
            expression: "Bericht[ix].values[idx].value"
          }
        })], 1)]) : _vm._e(), it.type == 'textarea' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))]), it.hvw == 'address' && _vm.cleanAddress.length > 25 ? _c('div', {
          staticClass: "fr"
        }, [_c('div', {
          staticClass: "link",
          on: {
            "click": function click($event) {
              _vm.Bericht[ix].values[idx].value = _vm.$_cp(_vm.cleanAddress);
            }
          }
        }, [_vm._v("Original Adresse übernehmen")])]) : _vm._e()]), _c('div', {
          staticClass: "list_value"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.Bericht[ix].values[idx].value,
            expression: "Bericht[ix].values[idx].value"
          }],
          domProps: {
            "value": _vm.Bericht[ix].values[idx].value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $event.target.value);
            }
          }
        })])]) : it.type == 'text' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))]), it.hvw == 'vnaddressname' && _vm.cleanVnName.length > 0 ? _c('div', {
          staticClass: "fr"
        }, [_c('div', {
          staticClass: "link",
          on: {
            "click": function click($event) {
              _vm.Bericht[ix].values[idx].value = _vm.$_cp(_vm.cleanVnName);
            }
          }
        }, [_vm._v("Original VN übernehmen")])]) : _vm._e(), it.hvw == 'polnr' && _vm.cleanPolNr.length > 0 ? _c('div', {
          staticClass: "fr"
        }, [_c('div', {
          staticClass: "link",
          on: {
            "click": function click($event) {
              _vm.Bericht[ix].values[idx].value = _vm.$_cp(_vm.cleanPolNr);
            }
          }
        }, [_vm._v("Original PolNr übernehmen")])]) : _vm._e()]), _c('div', {
          staticClass: "list_value"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.Bericht[ix].values[idx].value,
            expression: "Bericht[ix].values[idx].value"
          }],
          domProps: {
            "value": _vm.Bericht[ix].values[idx].value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $event.target.value);
            }
          }
        })])]) : it.type == 'date' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))])]), it.name == 'Begehung' ? _c('div', {
          staticClass: "list_value"
        }, [_c('input', {
          attrs: {
            "disabled": ""
          },
          domProps: {
            "value": _vm.$_byStr(_vm.currentProject, 'svData.besichtigungsDatum')
          }
        }), _vm._m(3, true)]) : _c('div', {
          staticClass: "list_value"
        }, [_c('DatePick', {
          attrs: {
            "format": _vm.MAIN_PICK_FORMAT,
            "displayFormat": _vm.MAIN_PICK_FORMAT,
            "hasInputElement": true
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $$v);
            },
            expression: "Bericht[ix].values[idx].value"
          }
        })], 1)]) : it.type == 'bool' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))])]), _c('div', {
          staticClass: "list_value"
        }, [_c('toggle-button', {
          attrs: {
            "color": "#82C7EB",
            "sync": true,
            "labels": {
              checked: 'JA',
              unchecked: 'NEIN'
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $$v);
            },
            expression: "Bericht[ix].values[idx].value"
          }
        })], 1)]) : it.type == 'booltext' ? _c('div', {
          staticClass: "litem"
        }, [_c('div', {
          staticClass: "topline"
        }, [_c('h3', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(it.name))])]), _c('div', {
          staticClass: "list_value"
        }, [_c('VueSelect', {
          attrs: {
            "label": "name",
            "options": _vm.vals[it.vals],
            "reduce": function reduce(itm) {
              return itm.name;
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value", $$v);
            },
            expression: "Bericht[ix].values[idx].value"
          }
        }), it.value == 'JA' ? _c('span', [_c('h3', [_vm._v(_vm._s(it.name2))]), it.vals2 ? _c('span', [_c('VueSelect', {
          attrs: {
            "label": "name",
            "options": _vm.vals[it.vals2],
            "reduce": function reduce(itm) {
              return itm.name;
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value2,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value2", $$v);
            },
            expression: "Bericht[ix].values[idx].value2"
          }
        })], 1) : _c('span', [_c('VueSelect', {
          attrs: {
            "label": "name",
            "options": _vm.vals["percent"],
            "reduce": function reduce(itm) {
              return itm.name;
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value2,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value2", $$v);
            },
            expression: "Bericht[ix].values[idx].value2"
          }
        })], 1), _c('br'), _c('h3', [_vm._v(_vm._s(it.name3))]), typeof it.value3 == 'string' ? _c('span', [_c('VueSelect', {
          attrs: {
            "label": "name",
            "options": _vm.vals[it.vals3],
            "reduce": function reduce(itm) {
              return itm.name;
            }
          },
          model: {
            value: _vm.Bericht[ix].values[idx].value3,
            callback: function callback($$v) {
              _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$v);
            },
            expression: "Bericht[ix].values[idx].value3"
          }
        }), _c('div', {
          staticClass: "btn _s _red",
          on: {
            "click": function click($event) {
              it.value3 = [it.value3];
            }
          }
        }, [_vm._v("Mehrfachauswahl")])], 1) : _c('span', [_c('div', {
          staticClass: "fr"
        }, [_c('span', {
          staticClass: "ic",
          on: {
            "click": function click($event) {
              return _vm.AddNewAttributeHEAD(ix, idx);
            }
          }
        }, [_c('i', {
          staticClass: "icon-add2"
        })])]), _c('div', {
          staticClass: "list_value flex"
        }, [_vm._l(_vm.vals[it.vals3], function (v) {
          return _c('label', {
            key: v.name + idx,
            staticClass: "list_value_item",
            class: {
              _sel: _vm.$_hasInArray(it.value3, v.name)
            },
            attrs: {
              "for": v.name + idx
            }
          }, [_c('span', [_vm.$_hasInArray(_vm.Bericht[ix].values[idx].value3, v.name) ? _c('i', {
            staticClass: "icon-check"
          }) : !_vm.$_hasInArray(_vm.Bericht[ix].values[idx].value3, v.name) ? _c('i', {
            staticClass: "icon-circle"
          }) : _vm._e(), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.Bericht[ix].values[idx].value3,
              expression: "Bericht[ix].values[idx].value3"
            }],
            staticClass: "check",
            attrs: {
              "type": "checkbox",
              "id": v.name + idx
            },
            domProps: {
              "value": v.name,
              "checked": Array.isArray(_vm.Bericht[ix].values[idx].value3) ? _vm._i(_vm.Bericht[ix].values[idx].value3, v.name) > -1 : _vm.Bericht[ix].values[idx].value3
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.Bericht[ix].values[idx].value3,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = v.name,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$c);
                }
              }
            }
          }), _c('span', [_vm._v(" " + _vm._s(v.name))])])]);
        }), _vm._l(_vm.Bericht[ix].values[idx].value3, function (v) {
          return !_vm.$_findKeyValInArr(_vm.vals[it.vals3], 'name', v) ? _c('label', {
            key: v + idx + idx + 'ccustom',
            staticClass: "list_value_item custom _sel",
            attrs: {
              "for": v + idx + idx + 'ccustom'
            }
          }, [_c('span', [!_vm.$_findKeyValInArr(_vm.vals[it.vals3], 'name', v) ? _c('i', {
            staticClass: "icon-check"
          }) : _vm.$_findKeyValInArr(_vm.vals[it.vals3], 'name', v) ? _c('i', {
            staticClass: "icon-circle"
          }) : _vm._e(), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.Bericht[ix].values[idx].value3,
              expression: "Bericht[ix].values[idx].value3"
            }],
            staticClass: "check",
            attrs: {
              "type": "checkbox",
              "id": v + idx + idx + 'ccustom'
            },
            domProps: {
              "value": v,
              "checked": Array.isArray(_vm.Bericht[ix].values[idx].value3) ? _vm._i(_vm.Bericht[ix].values[idx].value3, v) > -1 : _vm.Bericht[ix].values[idx].value3
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.Bericht[ix].values[idx].value3,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = v,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(_vm.Bericht[ix].values[idx], "value3", $$c);
                }
              }
            }
          }), _c('span', [_vm._v(" " + _vm._s(v) + " ")])])]) : _vm._e();
        })], 2)])]) : _vm._e()], 1)]) : _vm._e()]) : _vm._e();
      }), 0);
    })], 2) : berichtItem.type == 'headimage' || berichtItem.type == 'mapimage' ? _c('li', {
      staticClass: "MainBlock image",
      class: 'item_' + ix
    }, [_c('div', {
      staticClass: "blockfunctions bold"
    }, [_vm._v(_vm._s(berichtItem.title)), _c('small', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(berichtItem.type))])]), _c('div', {
      staticClass: "clear"
    }), _c('ul', {
      staticClass: "ulimages"
    }, [_vm._l(berichtItem.values, function (iitem, idx) {
      return _c('li', {
        staticClass: "images"
      }, [_c('div', {
        staticClass: "clear"
      }), _c('div', {
        staticClass: "tac"
      }, [_c('img', {
        staticClass: "docimage",
        attrs: {
          "src": iitem.url + _vm.tok,
          "width": "240px"
        },
        on: {
          "click": function click($event) {
            return _vm.ChooseAnotherImage(ix, idx);
          }
        }
      })]), _c('div', {
        staticClass: "tac"
      }, [_c('div', {
        staticClass: "caption"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: iitem.caption,
          expression: "iitem.caption"
        }],
        domProps: {
          "value": iitem.caption
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(iitem, "caption", $event.target.value);
          }
        }
      })])]), _c('div', {
        staticClass: "tac"
      }, [_c('div', {
        staticClass: "btn _s _red",
        on: {
          "click": function click($event) {
            return _vm.DeleteImage(ix, idx);
          }
        }
      }, [_vm._v("Löschen")])])]);
    }), _c('li', {
      staticClass: "images"
    }, [_c('span', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          return _vm.AddNewImage(ix);
        }
      }
    }, [_vm._m(4, true)])])], 2)]) : berichtItem.type == 'headcalculation' ? _c('li', {
      staticClass: "MainBlock",
      class: 'item_' + ix
    }, [_c('div', {
      staticClass: "blockfunctions bold"
    }, [_vm._v(_vm._s(berichtItem.title)), _c('small', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(berichtItem.type))])]), _vm.excelID >= 0 ? _c('div', {
      staticClass: "headcalc"
    }, [_c('HeadCalc', {
      model: {
        value: _vm.Bericht[_vm.excelID],
        callback: function callback($$v) {
          _vm.$set(_vm.Bericht, _vm.excelID, $$v);
        },
        expression: "Bericht[excelID]"
      }
    })], 1) : _vm._e()]) : berichtItem.type == 'buildings' ? _c('li', {
      staticClass: "MainBlock buildingsblock",
      class: 'item_' + ix
    }, [_c('div', {
      staticClass: "blockfunctions bold"
    }, [_c('h2', [_vm._v(_vm._s(berichtItem.title))])]), _c('div', {
      staticClass: "rel wz-tabs"
    }, [_vm._l(berichtItem.buildings, function (building, bidx) {
      return _c('div', {
        staticClass: "wz-tab",
        class: {
          _selected: _vm.selBuilding == bidx
        },
        on: {
          "click": function click($event) {
            _vm.selBuilding = bidx;
          }
        }
      }, [_vm._v("Gebäude " + _vm._s(bidx + 1))]);
    }), _c('div', {
      staticClass: "wz-tab",
      on: {
        "click": function click($event) {
          return _vm.AddNewBuilding(ix);
        }
      }
    }, [_c('i', {
      staticClass: "icon-add2"
    })])], 2), _c('ul', {
      staticClass: "buildings"
    }, _vm._l(berichtItem.buildings, function (buildingItem, bidx) {
      return _vm.selBuilding == bidx ? _c('li', _vm._l(buildingItem, function (building, bbidx) {
        return _c('ul', {
          staticClass: "buildings2"
        }, [building.type == 'binfo' ? _c('li', {
          staticClass: "MainBlock list"
        }, [_c('div', {
          staticClass: "blockfunctions bold"
        }, [_c('span', [_vm._v(_vm._s(building.title))]), _c('div', {
          staticClass: "fr"
        }, [_vm._v("Gebäude " + _vm._s(bidx + 1))])]), _c('div', {
          staticClass: "padding"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: building.values[0].value,
            expression: "building.values[0].value"
          }],
          domProps: {
            "value": building.values[0].value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(building.values[0], "value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "fr"
        }, [bidx !== 0 ? _c('div', {
          staticClass: "btn _s _red",
          on: {
            "click": function click($event) {
              return _vm.RemoveNewBuilding(ix, bidx);
            }
          }
        }, [_vm._v("Löschen")]) : _vm._e()]), _c('br'), _c('div', {
          staticClass: "fr"
        }, [berichtItem.buildings.length > 1 ? _c('div', {
          staticClass: "btn _s _green",
          on: {
            "click": function click($event) {
              return _vm.$modal.show('OverTakeBuilding');
            }
          }
        }, [_vm._v("Kopie von anderem Gbäude übernehmen")]) : _vm._e()])])]) : _vm._e(), building.type == 'eckdaten' ? _c('li', {
          staticClass: "MainBlock list"
        }, [_c('div', {
          staticClass: "blockfunctions bold"
        }, [_c('span', [_vm._v(_vm._s(building.title))]), _c('div', {
          staticClass: "fr"
        }, [_vm._v("Gebäude " + _vm._s(bidx + 1))])]), _c('ul', {
          staticClass: "fields"
        }, _vm._l(building.values, function (it, idx) {
          return _c('li', {
            staticClass: "flex"
          }, [it.type == 'select' ? _c('div', {
            staticClass: "litem"
          }, [_c('div', {
            staticClass: "topline"
          }, [_c('h3', {
            staticClass: "bold"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: it.name,
              expression: "it.name"
            }],
            domProps: {
              "value": it.name
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(it, "name", $event.target.value);
              }
            }
          })])]), typeof it.value == 'string' || typeof it.value == 'boolean' ? _c('div', {
            staticClass: "list_value"
          }, [_c('div', {
            staticClass: "selectvue"
          }, [_c('VueSelect', {
            attrs: {
              "label": "name",
              "options": _vm.vals[it.vals],
              "reduce": function reduce(itm) {
                return itm.name;
              }
            },
            model: {
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
              callback: function callback($$v) {
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$v);
              },
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
            }
          })], 1), _c('div', {
            staticClass: "btn _s _red",
            on: {
              "click": function click($event) {
                it.value = [it.value];
              }
            }
          }, [_vm._v("Mehrfachauswahl")])]) : _c('div', {
            staticClass: "list_value"
          }, [_c('div', {
            staticClass: "fr"
          }, [_c('div', {
            staticClass: "btn _s _clear",
            on: {
              "click": function click($event) {
                return _vm.AddNewAttribute(ix, bidx, bbidx, idx);
              }
            }
          }, [_c('i', {
            staticClass: "icon-add2"
          })])]), _c('hr'), _c('div', {
            staticClass: "list_value flex"
          }, [_vm._l(_vm.vals[it.vals], function (v) {
            return _c('label', {
              key: v.name + idx,
              staticClass: "list_value_item",
              class: {
                _sel: _vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name)
              },
              attrs: {
                "for": v.name + idx
              }
            }, [_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-check"
            }) : !_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox",
                "id": v.name + idx
              },
              domProps: {
                "value": v.name,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v.name,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(" " + _vm._s(v.name))])]);
          }), _vm._l(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, function (v) {
            return !_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('label', {
              key: v + idx + bidx + 'custom',
              staticClass: "list_value_item custom _sel"
            }, [!_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-check"
            }) : _vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox"
              },
              domProps: {
                "value": v,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(_vm._s(v) + " ")])]) : _vm._e();
          })], 2)])]) : _vm._e(), it.type == 'number' ? _c('div', {
            staticClass: "litem"
          }, [_c('div', {
            staticClass: "topline"
          }, [_c('h3', {
            staticClass: "bold"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: it.name,
              expression: "it.name"
            }],
            domProps: {
              "value": it.name
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(it, "name", $event.target.value);
              }
            }
          })])]), _c('div', {
            staticClass: "list_value flex"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model.number",
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value",
              modifiers: {
                "number": true
              }
            }],
            attrs: {
              "type": "number",
              "min": "0",
              "step": "1"
            },
            domProps: {
              "value": _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", _vm._n($event.target.value));
              },
              "blur": function blur($event) {
                return _vm.$forceUpdate();
              }
            }
          })])]) : _vm._e()]);
        }), 0), _c('div', {
          staticClass: "padding tac"
        }, [_c('div', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Geschosse über `Erdniveau` ohne `Dach` aber mit `Erdgeschoss` eingeben! <br> <br> `Nutzung-Liste neu erstellen` löscht die aktuellen `NUTZUNG`-Eingaben und erstellt die `NUTZUNG`-Liste neu anhand der Geschosse unter und über Erdniveau <br> Es ist rückgängig machbar, sofern es NICHT gespeichert wird!',
            expression: "'Geschosse über `Erdniveau` ohne `Dach` aber mit `Erdgeschoss` eingeben! <br> <br> `Nutzung-Liste neu erstellen` löscht die aktuellen `NUTZUNG`-Eingaben und erstellt die `NUTZUNG`-Liste neu anhand der Geschosse unter und über Erdniveau <br> Es ist rückgängig machbar, sofern es NICHT gespeichert wird!'"
          }],
          staticClass: "bold"
        }, [_c('i', {
          staticClass: "icon-info"
        }), _vm._v(" Anmerkungen")]), _c('br'), _c('br'), _c('span', [_c('div', {
          staticClass: "btn _s _red",
          on: {
            "click": function click($event) {
              return _vm.askCalculateFloors(bidx);
            }
          }
        }, [_vm._v("Nutzung-Liste neu erstellen!!")])])])]) : building.type == 'nutzung' ? _c('li', {
          staticClass: "MainBlock list"
        }, [_c('div', {
          staticClass: "blockfunctions bold"
        }, [_c('span', [_vm._v(_vm._s(building.title))]), _c('div', {
          staticClass: "fr"
        }, [_vm._v("Gebäude " + _vm._s(bidx + 1))])]), _c('ul', {
          staticClass: "fields"
        }, [_c('li'), _vm._l(building.values, function (it, idx) {
          return _c('li', {
            staticClass: "flex"
          }, [it.type == 'select' ? _c('div', {
            staticClass: "litem"
          }, [_c('div', {
            staticClass: "topline"
          }, [_c('h3', {
            staticClass: "bold"
          }, [_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].required ? _c('span', [_vm._v("ERDNIVEAU")]) : _vm._e(), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].name,
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].name"
            }],
            domProps: {
              "value": _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].name
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "name", $event.target.value);
              }
            }
          })]), _c('div', {
            staticClass: "fr"
          }, [building._meta ? _c('div', {
            staticClass: "choosefloor"
          }, [_c('VueSelect', {
            attrs: {
              "label": "name",
              "options": building._meta.vals,
              "reduce": function reduce(itm) {
                return itm.name;
              }
            },
            model: {
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].vals,
              callback: function callback($$v) {
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "vals", $$v);
              },
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].vals"
            }
          })], 1) : _vm._e(), _c('span', {
            staticClass: "ic",
            on: {
              "click": function click($event) {
                return _vm.AddNewAttribute(ix, bidx, bbidx, idx);
              }
            }
          }, [_c('i', {
            staticClass: "icon-add2"
          })]), _c('span', {
            staticClass: "ic",
            on: {
              "click": function click($event) {
                return _vm.DeleteFloor(ix, bidx, bbidx, idx);
              }
            }
          }, [_c('i', {
            staticClass: "icon-trashbin"
          })])])]), _c('div', {
            staticClass: "list_value flex"
          }, [_vm._l(_vm.vals[it.vals], function (v) {
            return _c('label', {
              key: v.name + idx,
              staticClass: "list_value_item",
              class: {
                _sel: _vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name)
              }
            }, [_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-check"
            }) : !_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox"
              },
              domProps: {
                "value": v.name,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v.name,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(" " + _vm._s(v.name))])]);
          }), _vm._l(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, function (v) {
            return !_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('label', {
              key: v + idx + bidx + 'custom',
              staticClass: "list_value_item custom _sel",
              attrs: {
                "for": v + idx + bidx + 'custom'
              }
            }, [!_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-check"
            }) : _vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox",
                "id": v + idx + bidx + 'custom'
              },
              domProps: {
                "value": v,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(" " + _vm._s(v) + " ")])]) : _vm._e();
          })], 2)]) : _vm._e()]);
        }), _c('li', [_c('br'), _c('div', {
          staticClass: "btn _s _green",
          on: {
            "click": function click($event) {
              return _vm.AddNewFloor(ix, bidx, bbidx);
            }
          }
        }, [_vm._v("Neues Geschoss  "), _c('i', {
          staticClass: "icon-add2"
        })])])], 2)]) : building.type == 'list' ? _c('li', {
          staticClass: "MainBlock list"
        }, [_c('div', {
          staticClass: "blockfunctions bold"
        }, [_c('span', [_vm._v(_vm._s(building.title))]), _c('div', {
          staticClass: "fr"
        }, [_vm._v("Gebäude " + _vm._s(bidx + 1))])]), _c('ul', {
          staticClass: "fields"
        }, _vm._l(building.values, function (it, idx) {
          return _c('li', {
            staticClass: "flex"
          }, [it.type == 'select' ? _c('div', {
            staticClass: "litem"
          }, [_c('div', {
            staticClass: "topline"
          }, [_c('h3', {
            staticClass: "bold"
          }, [_vm._v(_vm._s(it.name))]), _c('div', {
            staticClass: "fr"
          }, [_c('div', {
            staticClass: "btn _s _clear",
            on: {
              "click": function click($event) {
                return _vm.AddNewAttribute(ix, bidx, bbidx, idx);
              }
            }
          }, [_c('i', {
            staticClass: "icon-add2"
          })])])]), _c('div', {
            staticClass: "list_value flex"
          }, [_vm._l(_vm.vals[it.vals], function (v) {
            return _c('label', {
              key: v.name + idx,
              staticClass: "list_value_item",
              class: _vm.checkShower(it, v.name)
            }, [_vm.checkShower(it, v.name)._hvw ? _c('span', {
              on: {
                "click": function click($event) {
                  return _vm.OpenHVWChooser(ix, bidx, bbidx, idx, v.name);
                }
              }
            }, [_vm._v(" " + _vm._s(v.name))]) : _c('span', [_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-check"
            }) : !_vm.$_hasInArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox"
              },
              domProps: {
                "value": v.name,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v.name) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v.name,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(" " + _vm._s(v.name))])])]);
          }), _vm._l(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, function (v) {
            return !_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('label', {
              key: v + idx + bidx + 'custom',
              staticClass: "list_value_item custom _sel",
              attrs: {
                "for": v + idx + bidx + 'custom'
              }
            }, [!_vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-check"
            }) : _vm.$_findKeyValInArr(_vm.vals[it.vals], 'name', v) ? _c('i', {
              staticClass: "icon-circle"
            }) : _vm._e(), _c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
              }],
              staticClass: "check",
              attrs: {
                "type": "checkbox",
                "id": v + idx + bidx + 'custom'
              },
              domProps: {
                "value": v,
                "checked": Array.isArray(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value) ? _vm._i(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value, v) > -1 : _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = v,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $$c);
                  }
                }
              }
            }), _c('span', [_vm._v(" " + _vm._s(v) + " ")])]) : _vm._e();
          })], 2)]) : _c('div', {
            staticClass: "litem"
          }, [_c('pre', [_vm._v("--")]), _c('span', {
            staticClass: "bold tab_name"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: it.name,
              expression: "it.name"
            }],
            domProps: {
              "value": it.name
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(it, "name", $event.target.value);
              }
            }
          })]), _c('span', {
            staticClass: "tab_value"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: it.value,
              expression: "it.value"
            }],
            domProps: {
              "value": it.value
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(it, "value", $event.target.value);
              }
            }
          })])])]);
        }), 0)]) : building.type == 'text' ? _c('li', {
          staticClass: "MainBlock list"
        }, [_c('div', {
          staticClass: "blockfunctions bold"
        }, [_c('span', [_vm._v(_vm._s(building.title))]), _c('div', {
          staticClass: "fr"
        }, [_vm._v("Gebäude " + _vm._s(bidx + 1))])]), _c('ul', {
          staticClass: "fields"
        }, _vm._l(building.values, function (it, idx) {
          return _c('li', {
            staticClass: "flex"
          }, [_c('div', {
            staticClass: "litem"
          }, [_c('div', {
            staticClass: "topline"
          }, [_c('h3', {
            staticClass: "bold"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].name,
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].name"
            }],
            domProps: {
              "value": _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].name
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "name", $event.target.value);
              }
            }
          })])]), _c('div', {
            staticClass: "list_value flex"
          }, [_c('textarea', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value,
              expression: "Bericht[ix].buildings[bidx][bbidx].values[idx].value"
            }],
            staticClass: "textblock",
            domProps: {
              "value": _vm.Bericht[ix].buildings[bidx][bbidx].values[idx].value
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(_vm.Bericht[ix].buildings[bidx][bbidx].values[idx], "value", $event.target.value);
              }
            }
          })])])]);
        }), 0)]) : _vm._e()]);
      }), 0) : _vm._e();
    }), 0)]) : berichtItem.type == 'excel' ? _c('li', {
      staticClass: "MainBlock",
      class: 'item_' + ix
    }, [_c('div', {
      staticClass: "blockfunctions bold"
    }, [_vm._v(_vm._s(berichtItem.title)), _c('small', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(berichtItem.type))])]), _c('div', {
      staticClass: "wz-tabs fl"
    }, [_c('div', {
      staticClass: "wz-tab",
      class: {
        _selected: _vm.Bericht[ix].variant == 'v1'
      },
      on: {
        "click": function click($event) {
          _vm.Bericht[ix].variant = "v1";
        }
      }
    }, [_vm._v("Variante 1 (standard)")]), _c('div', {
      staticClass: "wz-tab",
      class: {
        _selected: _vm.Bericht[ix].variant == 'v2'
      },
      on: {
        "click": function click($event) {
          _vm.Bericht[ix].variant = "v2";
        }
      }
    }, [_vm._v("Variante 2 (nach Wohnnutzfläche)")]), _c('div', {
      staticClass: "wz-tab",
      class: {
        _selected: _vm.Bericht[ix].variant == 'v3'
      },
      on: {
        "click": function click($event) {
          _vm.Bericht[ix].variant = "v3";
        }
      }
    }, [_vm._v("Variante 3 (freie Bewertungstabelle)")])]), _c('div', {
      staticClass: "clear"
    }, [_vm.Bericht[ix].variant == 'v1' ? _c('span', [_c('EXCEL', {
      attrs: {
        "excelid": ix,
        "binfo": _vm.Bericht[_vm.buildingsID].buildings
      },
      on: {
        "UpdateExcel": _vm.UpdateExcel
      },
      model: {
        value: _vm.Bericht[ix],
        callback: function callback($$v) {
          _vm.$set(_vm.Bericht, ix, $$v);
        },
        expression: "Bericht[ix]"
      }
    })], 1) : _vm.Bericht[ix].variant == 'v2' ? _c('span', [_c('EXCEL2', {
      attrs: {
        "excelid": ix,
        "binfo": _vm.Bericht[_vm.buildingsID].buildings
      },
      on: {
        "UpdateExcel": _vm.UpdateExcel
      },
      model: {
        value: _vm.Bericht[ix],
        callback: function callback($$v) {
          _vm.$set(_vm.Bericht, ix, $$v);
        },
        expression: "Bericht[ix]"
      }
    })], 1) : _vm.Bericht[ix].variant == 'v3' ? _c('span', [_c('EXCEL3', {
      attrs: {
        "excelid": ix,
        "binfo": _vm.Bericht[_vm.buildingsID].buildings
      },
      on: {
        "UpdateExcel": _vm.UpdateExcel
      },
      model: {
        value: _vm.Bericht[ix],
        callback: function callback($$v) {
          _vm.$set(_vm.Bericht, ix, $$v);
        },
        expression: "Bericht[ix]"
      }
    })], 1) : _vm._e(), _c('hr'), _vm._m(5, true), _c('div', {
      staticClass: "spacer _vertical"
    })])]) : _vm._e()]);
  }), 0) : !_vm.isReportDataLoaded ? _c('span', {
    staticClass: "tac"
  }, [_c('h3', [_vm._v("Keine Daten verfügbar")]), _vm.$_size(_vm.projectDataFromStore.hvData) == 0 ? _c('div', {
    staticClass: "tac"
  }, [_c('p', [_vm._v("Bevor der Bericht begonnen werden kann, müssen HV-Daten vorhanden sein. Wenn noch keine vorhanden sind, bitte im Reiter \"HV-Daten\" eine Vorlage anlegen.")])]) : _vm.isReportDataLoadedSOMEOTHERERROR ? _c('div', {
    staticClass: "tac"
  }, [_c('br'), _c('br'), _vm._m(6), _c('br')]) : _vm._e(), _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "btn _red",
    on: {
      "click": function click($event) {
        return _vm.askSetUpNewReport();
      }
    }
  }, [_vm._v("Vorlage neu erstellen!")]), _c('br'), _c('br'), _c('p', [_vm._v("Wenn noch keine Daten angelegt sind/waren, denn kann eine Vorlage erstellt werden!")]), _c('br')]), _c('br')]) : _c('span', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })])])])]), _c('div', {
    staticClass: "spacer _vertical _verylarge"
  })])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "SelectANewBlock",
      "name": "SelectANewBlock",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Block auswählen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SelectANewBlock');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h3', [_vm._v("Block auswählen")]), _c('ul', _vm._l(_vm.AllTypes, function (type, idx) {
    return _c('li', {
      staticClass: "selectblock"
    }, [_c('div', {
      staticClass: "link",
      on: {
        "click": function click($event) {
          return _vm.SelectThisBlock(type);
        }
      }
    }, [_vm._v(_vm._s(type.type))])]);
  }), 0)]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SelectANewBlock');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.SelectThisBlock();
      }
    }
  }, [_vm._v("OK")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ListOfFilesForInput",
      "name": "ListOfFilesForInput",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Bilder einfügen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ListOfFilesForInput');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('DateienAllianz', {
    attrs: {
      "view": 'files',
      "currentProjectUid": _vm.currentProjectUid,
      "from": "doc"
    },
    on: {
      "emited": _vm.EmitedFromFilesInput
    }
  })], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ListOfFilesForInput');
      }
    }
  }, [_vm._v("Abbrechen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowBericht",
      "name": "ShowBericht",
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Bericht "), _c('a', {
    attrs: {
      "href": _vm.$env.mainurl + _vm.pdfFileDocument + _vm.tok
    }
  }, [_vm._v(_vm._s(_vm.pdfFileDocument))]), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowBericht');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "embeded"
  }, [_c('embed', {
    attrs: {
      "src": _vm.$env.mainurl + _vm.pdfFileDocument + _vm.tok,
      "width": "100%",
      "height": "100%",
      "type": "application/pdf"
    }
  })])])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "AddNewFieldModal",
      "name": "AddNewFieldModal",
      "adaptive": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Neues Feld eingeben:"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AddNewFieldModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "padding"
  }, [_c('p', {
    staticClass: "bold"
  }, [_vm._v("Hier neues Feld eingeben:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.NewFieldForAtts,
      expression: "NewFieldForAtts"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.NewFieldForAtts
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.NewFieldForAtts = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AddNewFieldModal');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.SaveNewField();
      }
    }
  }, [_vm._v("OK")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "AddNewFieldModalHEAD",
      "name": "AddNewFieldModalHEAD",
      "adaptive": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Neues Feld eingeben:"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AddNewFieldModalHEAD');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "padding"
  }, [_c('p', {
    staticClass: "bold"
  }, [_vm._v("Hier neues Feld eingeben:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.NewFieldForAttsHEAD,
      expression: "NewFieldForAttsHEAD"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.NewFieldForAttsHEAD
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.NewFieldForAttsHEAD = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AddNewFieldModalHEAD');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.SaveNewFieldHEAD();
      }
    }
  }, [_vm._v("OK")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "OverTakeBuilding",
      "name": "OverTakeBuilding",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Gebäude übernehmen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('OverTakeBuilding');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block padding"
  }, [_c('p', {
    staticClass: "tac"
  }, [_vm._v("Die Einstellungen (außer die Berechnung (Excel)) wird von diesem Gebäude übernommen.")]), _c('br'), _c('ul', _vm._l(((_vm.Bericht || {})[_vm.buildingsID] || {}).buildings, function (b, i) {
    return _c('li', {
      staticClass: "padding"
    }, [_c('span', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          return _vm.OvertakeCopy(i);
        }
      }
    }, [_vm._v("Gebäude " + _vm._s(i + 1))])]);
  }), 0), _c('br'), _c('p', {
    staticClass: "tac bold"
  }, [_vm._v("Bitte beachten, dass alle Auswahlen für das Gebäude " + _vm._s(_vm.selBuilding + 1) + " dadurch überschrieben werden!")])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('OverTakeBuilding');
      }
    }
  }, [_vm._v("Abbrechen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "OpenChooserModal",
      "name": "OpenChooserModal",
      "adaptive": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenModal
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("HVW Daten"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('OpenChooserModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block"
  }, [_vm.ChooseThis.ix >= 0 && _vm.Bericht[_vm.ChooseThis.ix].buildings[_vm.ChooseThis.bidx][_vm.ChooseThis.bbidx].values[_vm.ChooseThis.idx].hvw_value ? _c('div', {
    staticClass: "padding"
  }, [_c('h3', [_vm._v(_vm._s(_vm.ChooseThis.name))]), _c('br'), _c('p', [_vm._v("HV hat diesen Wert befüllt: " + _vm._s(_vm.ChooseThis.name))]), _c('br'), _c('div', {
    staticClass: "padding2"
  }, [_c('label', {
    staticClass: "radio"
  }, [_vm._v("Bestätigen (wenn SV es \"mit eigenen Augen gesehen hat\")"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ChooseThis.state,
      expression: "ChooseThis.state"
    }],
    attrs: {
      "type": "radio",
      "value": "accept",
      "name": "hvwchooser"
    },
    domProps: {
      "checked": _vm._q(_vm.ChooseThis.state, "accept")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.ChooseThis, "state", "accept");
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]), _c('label', {
    staticClass: "radio"
  }, [_vm._v("Ablehnen (weil es eine falsche Angabe ist)"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ChooseThis.state,
      expression: "ChooseThis.state"
    }],
    attrs: {
      "type": "radio",
      "value": "decline",
      "name": "hvwchooser"
    },
    domProps: {
      "checked": _vm._q(_vm.ChooseThis.state, "decline")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.ChooseThis, "state", "decline");
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]), _c('label', {
    staticClass: "radio"
  }, [_vm._v("weder noch, im pdf-Bericht wird vermerkt, dass dieser Wert von der HV kommt und nicht bestätigt oder abgelehnt werden kann"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ChooseThis.state,
      expression: "ChooseThis.state"
    }],
    attrs: {
      "type": "radio",
      "value": "keep",
      "name": "hvwchooser"
    },
    domProps: {
      "checked": _vm._q(_vm.ChooseThis.state, "keep")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.ChooseThis, "state", "keep");
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })])])]) : _vm._e()]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('OpenChooserModal');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.SaveChooser();
      }
    }
  }, [_vm._v("Speichern")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("Bericht")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("Bericht")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('small', [_vm._v("(dieser Wert ist im "), _c('b', [_vm._v("\"Allgemein\"")]), _vm._v(" Teil zu ändern.)")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('small', [_vm._v("(dieser Wert ist im "), _c('b', [_vm._v("\"Allgemein\"")]), _vm._v(" Teil zu ändern, Feld "), _c('b', [_vm._v("\"SV Status, besichtigt am\"")]), _vm._v(")")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tac"
  }, [_c('i', {
    staticClass: "icon-add _verylarge"
  }), _c('div', {
    staticClass: "boldBild"
  }, [_vm._v("Bild hinzufügen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "paddding"
  }, [_c('div', [_c('h4', [_c('i', {
    staticClass: "icon-info"
  }), _vm._v(" Anmerkungen")]), _c('span', [_vm._v("- Bezeichnungen und Geschossnamen werden aus der \"NUTZUNG\"-Liste genommen! AUSGENOMMEN das Dach!")]), _c('br'), _c('span', [_vm._v("- Bitte in dieser Reihenfolge vorgehen: ")]), _c('br'), _c('ul', {
    staticClass: "padding"
  }, [_c('li', [_vm._v("- ERST Geschosszahlen eingeben")]), _c('li', [_vm._v("- DANN Nutzungsdaten ausfüllen")]), _c('li', [_vm._v("- DANN Berechnungstabelle ausfüllen")])]), _c('br'), _c('h5', [_vm._v("Sonstige Punkte")]), _c('ul', {
    staticClass: "padding"
  }, [_c('li', [_vm._v("- Sollten nachträglich die Geschosszahlen oder die Nutzung-Geschossart (geschosse oder geschosse_dach) geändert werden, dann bitte die Berechnungstabelle nochmal kontrollieren!")]), _c('li', [_vm._v("- Dezimalzahlen in der Eingabe werden hier mit einem \"Punkt\" eingegeben, werden aber bei der PDF-Ausgabe auf ein \"Komma\" umgestellt.")]), _c('li', [_vm._v("- Die Tausender-Trennzeichen sind in der PDF-Ausgabe ein \"Punkt\"")]), _c('li', [_vm._v("- Alle Zahlen werden auf 2 Nachkomma-Stellen gerundet und angezeigt.")]), _c('li', [_vm._v("- Die Neubauwerte der Geschosse werden auf den \"nächsten Tausender\" aufgerundet")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('span', {
    staticClass: "bold _redp"
  }, [_vm._v("NETZWERK PROBLEM: ")]), _vm._v("Sollten Daten schon vorhanden sein, dann bitte versuchen noch einmal diese zu laden.")]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tfolder"
  }, [_vm._m(0), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.folder.name === '_originalBilder' ? 'Bilder' : _vm.folder.name)), _c('small', {
    staticClass: "color-lightgrey"
  }, [_c('span', [_vm._v("(" + _vm._s((_vm.folder.folder || {}).childCount) + ")")]), _c('span', [_vm._v(_vm._s(_vm.$_toHumanFileSize(_vm.folder.size)))])])]), _c('span', {
    staticClass: "icfolder",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilesToUpload', {
          folder: _vm.folder.name
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  })]), _c('span', {
    staticClass: "icfolder"
  }, [_c('ZipDownFolder', {
    attrs: {
      "folderName": _vm.folder.name,
      "folderId": _vm.folder.id,
      "currendId": _vm.currendId
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "icfolder"
  }, [_c('i', {
    staticClass: "icon-folder"
  })]);
}];
export { render, staticRenderFns };
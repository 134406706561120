import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tfile",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilePreview', {
          file: _vm.item,
          index: _vm.index,
          folder: _vm.folder
        });
      }
    }
  }, [_c('span', [_c('span', [_c('img', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.makeImageSp(_vm.item),
        placement: 'right-end',
        offset: 10,
        delay: {
          show: 20,
          hide: 20
        }
      },
      expression: "{content: makeImageSp(item),placement: 'right-end', offset:10, delay: {show: 20,hide: 20}}"
    }],
    staticClass: "thumbnail",
    attrs: {
      "src": _vm.$_makeImgThumbnailUrl(_vm.item, 560, _vm.spToken)
    }
  })])]), _c('span', {
    staticClass: "mulitline",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilePreview', {
          file: _vm.item,
          index: _vm.index,
          folder: _vm.folder
        });
      }
    }
  }, [_c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.item.name))])]), _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "modified"
  }, [_vm._v(_vm._s(_vm.$dayjs((_vm.item.photo || {}).takenDateTime || _vm.item.lastModifiedDateTime || '').format(_vm.MAIN_DATE_FORMAT)) + "  ")]), _c('div', {
    staticClass: "size"
  }, [_vm._v("(" + _vm._s(_vm.$_toHumanFileSize(_vm.item.size)) + ")")])])]), _c('span', {
    staticClass: "iconblock",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('span', {
    staticClass: "ic",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FileRenamerAllianz', {
          file: _vm.item
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-label"
  })]), _c('a', {
    staticClass: "ic",
    attrs: {
      "href": _vm.item['@content.downloadUrl'],
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon-download"
  })]), _c('span', {
    staticClass: "ic",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.askDeleteFile(_vm.item);
      }
    }
  }, [_c('i', {
    staticClass: "icon-trashbin"
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
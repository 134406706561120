import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "AllianzAktContacts",
  props: ["value", "hvUidFromParent", "emailReceiverFromParent"],
  data: function data() {
    return {
      isShowingContacts: true,
      contactData: {},
      hvUid: "",
      emailReceiver: "",
      contactDataInitial: {}
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.contactData = val;
      }
    },
    hvUidFromParent: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.hvUid = val;
      }
    },
    emailReceiverFromParent: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.emailReceiver = val;
      }
    },
    hvUid: function hvUid() {
      this.$emit("updateHvUid", this.hvUid);
    },
    emailReceiver: function emailReceiver() {
      this.$emit("updateEmailReceiver", this.emailReceiver);
    },
    contactData: {
      deep: true,
      // immediate: true,
      handler: function handler(val) {
        // this.updateData();
      }
    }
  },
  computed: _objectSpread({}, mapGetters(["_fbHausverwaltungen"])),
  methods: {
    updateData: function updateData() {
      // this.$emit("input", { ...this.contactDataInitial, ...this.contactData });
    },
    AddContact: function AddContact(akt, which) {
      var nuwcon = {};
      if (akt) {
        nuwcon = {
          type: which,
          name: this.currentProject.allianzData.contact_name,
          email: this.currentProject.allianzData.contact_email,
          phone: this.currentProject.allianzData.contact_phone
        };
      } else {
        nuwcon = {
          type: which,
          name: "name",
          email: "email",
          phone: "phone"
        };
      }
      this.$set(this.contactData, which, nuwcon);
    },
    DeleteContact: function DeleteContact(whichIdx) {
      this.$delete(this.contactData, whichIdx);
    }
  }
};
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "headcalc"
  }, [_c('span', [_c('h2', [_vm._v("Gebäudeneubauwert ")]), _c('div', {
    staticClass: "fr"
  }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(_vm.item.table.total.cost, -3)))]), _c('h4', [_vm._v("ZUSAMMENFASSUNG SUMMEN")]), _vm.isVariant1 ? _c('span', [_c('table', [_vm._m(0), _c('tbody', [_vm._l(_vm.item.table.allFloorsData, function (f, i) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(_vm.item.table.allFloorsData[i].name))]), _c('td', [_vm._v(_vm._s(_vm.item.table.allFloorsData[i].area) + " m"), _c('sup', [_vm._v("2")])]), _c('td', [_vm._v(_vm._s(_vm.$_roundMoney(_vm.item.table.allFloorsData[i].vol, 2)) + " m"), _c('sup', [_vm._v("3")])]), _c('td', [_vm._v("€ " + _vm._s(_vm.$_roundMoney(_vm.item.table.allFloorsData[i].cost, -3)))])]);
  }), _vm._l(_vm.item.table.misc, function (t) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(t.name))]), _c('td'), _c('td'), _c('td', [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(t.cost, -3)))])]);
  })], 2)])]) : _vm._e(), _vm.isVariant2 ? _c('span', [_c('table', [_vm._m(1), _c('tbody', [_vm._l(_vm.item.table.allFloorsData, function (f, i) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(_vm.item.table.allFloorsData[i].name))]), _c('td', [_vm._v(_vm._s(_vm.item.table.allFloorsData[i].area) + " m"), _c('sup', [_vm._v("2")])]), _c('td', [_vm._v("€ " + _vm._s(_vm.$_roundMoney(_vm.item.table.allFloorsData[i].cost, -3)))])]);
  }), _vm._l(_vm.item.table.misc, function (t) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(t.name))]), _c('td'), _c('td', [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(t.cost, -3)))])]);
  })], 2)])]) : _vm._e(), _vm.isVariant3 ? _c('span', [_c('table', [_vm._m(2), _c('tbody', [_vm._l(_vm.item.table.allFloorsData, function (f, i) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(_vm.item.table.allFloorsData[i].name))]), _c('td', [_vm._v("€ " + _vm._s(_vm.$_roundMoney(_vm.item.table.allFloorsData[i].cost, -3)))])]);
  }), _vm._l(_vm.item.table.misc, function (t) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(t.name))]), _c('td', [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(t.cost, -3)))])]);
  })], 2)])]) : _vm._e(), _c('div', {
    staticClass: "subtext"
  }, [_vm._v("inkl. Planungs- und Konstruktionskosten exkl. Steuer ")]), _c('div', {
    staticClass: "tac"
  }, [_vm._v("Alle Werte gerundet und ohne Mehrwertsteuer angegeben. ")]), _c('div', {
    staticClass: "endvalue"
  }, [_vm._v("€ " + _vm._s(_vm.$_ceilMoney(_vm.item.table.total.cost, -3)))])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Bezeichnung")]), _c('th', [_vm._v("BGF")]), _c('th', [_vm._v("Umbauter Raum")]), _c('th', [_vm._v("NBW")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Bezeichnung")]), _c('th', [_vm._v("WNFL")]), _c('th', [_vm._v("NBW")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Bezeichnung")]), _c('th', [_vm._v("NBW")])])]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card AllianzAktSvStatus"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingSvDaten = !_vm.isShowingSvDaten;
      }
    }
  }, [_vm._v("SV Status")])]), _vm.isShowingSvDaten ? _c('div', {
    staticClass: "card-content"
  }, [_c('span', [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Objekt besichtigt:")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#67ab7b",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.svData.isBesichtigt,
      callback: function callback($$v) {
        _vm.$set(_vm.svData, "isBesichtigt", $$v);
      },
      expression: "svData.isBesichtigt"
    }
  })], 1)]), _vm.svData.isBesichtigt ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("besichtigt am:")]), _c('div', {
    staticClass: "input"
  }, [_c('v-date', {
    attrs: {
      "label": 'Besichtigungstermin:'
    },
    model: {
      value: _vm.svData.besichtigungsDatum,
      callback: function callback($$v) {
        _vm.$set(_vm.svData, "besichtigungsDatum", $$v);
      },
      expression: "svData.besichtigungsDatum"
    }
  }), _vm._m(0)], 1)]) : _vm._e(), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Massen ermittelt")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#67ab7b",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.svData.isMassenErmittelt,
      callback: function callback($$v) {
        _vm.$set(_vm.svData, "isMassenErmittelt", $$v);
      },
      expression: "svData.isMassenErmittelt"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Bereit für Einheitswertermittlung (fertig für weitere Bearbeitung)")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#67ab7b",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.svData.isBereitFuerEinheitswertErmittlung,
      callback: function callback($$v) {
        _vm.$set(_vm.svData, "isBereitFuerEinheitswertErmittlung", $$v);
      },
      expression: "svData.isBereitFuerEinheitswertErmittlung"
    }
  }), _vm._isExternSv ? _c('span', [_vm._v("*")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "clear"
  }), _vm._isExternSv && _vm.svData.isBereitFuerEinheitswertErmittlung ? _c('small', {
    staticClass: "bold color-red"
  }, [_vm._v("* Sobald das aktiviert wird, ist die Bearbeitung für den externen SV gesperrt. "), _c('br'), _c('span', [_vm._v("Um weitere Änderungen vorzunehmen, sollte der zuständige SV oder das Consense Office kontaktiert werden.")])]) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "fr"
  }, [_c('small', [_vm._v("Dieser Wert wird in den Bericht übernommen als Begehungsdatum")])]);
}];
export { render, staticRenderFns };
var templateAkt = {
  "id": "",
  "loc": {},
  "hvData": {},
  "emailReceiver": "",
  "notices": "",
  "noticesSv2": "",
  "flags": {},
  "groups": [],
  "gs": "",
  "hvUid": "",
  "svUid": "",
  "svUid2": "",
  "besichtigungsTermin": "",
  "contact": {},
  "reportData": {
    "html": "[]"
  },
  "allianzData": {
    "tranche": "",
    "ld": "",
    "contact_email": "",
    "contact_phone": "",
    "plz_risiko": 0,
    "ortschaft_risiko": "",
    "adresse_risiko_zusatz": "",
    "name": "",
    "vs_feuer": 0,
    "insp": "",
    "adresse_risiko": "",
    "vorname": "",
    "contact_name": "",
    "flottenname": "",
    "polnr": ""
  },
  "modified": 0,
  "consenseNr": "",
  "_history": [],
  "__history": {},
  "svData": {},
  // "dsData": {},
  "status": ""
};
export default templateAkt;
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "dsData"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('p', [_vm._v(_vm._s(_vm.address))]), !_vm.dsData ? _c('span', [_c('pre', [_vm._v("kein Denkmalschutz Objekt!")])]) : !_vm.dsData.data ? _c('span', [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.createTables
    }
  }, [_vm._v("Tabelle erstellen")])]) : _c('span', [_c('ul', [_vm._l(_vm.dsData.data, function (item, ix) {
    return _c('li', [_c('div', {
      staticClass: "label input-field"
    }, [_c('div', {
      staticClass: "label _large"
    }, [item.isOptional ? _c('span', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.description,
        expression: "item.description"
      }],
      staticClass: "fullwidth",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.description
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "description", $event.target.value);
        }
      }
    })]) : _c('span', [_vm._v(_vm._s(item.description) + "  "), _c('button', {
      staticClass: "btn _inline _clear",
      on: {
        "click": function click($event) {
          return _vm.makeFieldOptional(ix);
        }
      }
    }, [_c('i', {
      staticClass: "icon-label"
    })])])]), _c('div', {
      staticClass: "input"
    }, [_c('div', {
      staticClass: "wz-flexer _jcsb"
    }, [_c('span', [_vm._v("Anzeigen:  "), _c('toggle-button', {
      attrs: {
        "color": "#6eb595",
        "height": 18,
        "width": 56,
        "sync": true,
        "labels": {
          checked: 'JA',
          unchecked: 'NEIN'
        }
      },
      model: {
        value: _vm.dsData.data[ix].value,
        callback: function callback($$v) {
          _vm.$set(_vm.dsData.data[ix], "value", $$v);
        },
        expression: "dsData.data[ix].value"
      }
    })], 1), _c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "right"
      }
    }, [_c('button', {
      staticClass: "btn _small _clear color-red"
    }, [_vm._v("Feld löschen")]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', {
      staticClass: "tac color-red"
    }, [_c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.removeField(ix);
        }
      }
    }, [_vm._v("Sicher Feld löschen?")])])])])], 2)], 1), _vm.dsData.data[ix].value ? _c('TextareaAutosize', {
      staticClass: "min32px",
      attrs: {
        "placeholder": "ggf. Erläuterung",
        "min-height": 12,
        "max-height": 480
      },
      model: {
        value: _vm.dsData.data[ix].comments,
        callback: function callback($$v) {
          _vm.$set(_vm.dsData.data[ix], "comments", $$v);
        },
        expression: "dsData.data[ix].comments"
      }
    }) : _vm._e()], 1)])]);
  }), _c('li', [_c('button', {
    staticClass: "btn _small",
    on: {
      "click": _vm.addNewField
    }
  }, [_vm._v("Neues Feld")])]), _c('li', [_c('div', {
    staticClass: "label input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Aufwand")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.dsData.effort,
      expression: "dsData.effort",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.dsData.effort
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.dsData, "effort", _vm._n($event.target.value));
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])])], 2), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.prepareExport
    }
  }, [_vm._v("Download als XLS")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h3', [_vm._v("Denkmalschutz")])]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', {
    staticClass: "allianzakt-grid"
  }, [_c('div', {
    staticClass: "flex-akt"
  }, [_vm.isShowingAktList ? _c('div', {
    staticClass: "side-kick"
  }, [_c('SideListInAkt', {
    attrs: {
      "aktUid": _vm.currentProjectUid
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "main-akt"
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    key: _vm.currentProjectUid,
    staticClass: "trhead"
  }, [_c('div', {
    staticClass: "akt-head"
  }, [!_vm.isLoadingAkt ? _c('div', {
    staticClass: "card _less"
  }, [_c('div', {
    staticClass: "card-header ovauto"
  }, [_c('div', {
    staticClass: "wz-tabs"
  }, [_c('div', {
    staticClass: "wz-tab",
    on: {
      "click": function click($event) {
        _vm.isShowingAktList = !_vm.isShowingAktList;
      }
    }
  }, [_c('i', {
    staticClass: "icon-list"
  }), _c('span', [_vm._v(" Liste")])]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'general'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'general';
      }
    }
  }, [_vm._v("Allgemein")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'hvw'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'hvw';
      }
    }
  }, [_vm._v("HV-Daten")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'doc'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'doc';
      }
    }
  }, [_vm._v("Bericht")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'files'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'files';
      }
    }
  }, [_vm._v("Dateien")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'filesnew'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'filesnew';
      }
    }
  }, [_c('span', {
    staticClass: "color-lightgrey"
  }, [_vm._v("DateienV2")])]), _vm.projectFromStore.flags.isDenkmalschutz ? _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'Denkmalschutz'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'denkmalschutz';
      }
    }
  }, [_vm._v("Denkmalschutz")]) : _vm._e(), _vm.projectFromStore.flags.isMehraufwand ? _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.selTab == 'Mehraufwand'
    },
    on: {
      "click": function click($event) {
        _vm.selTab = 'mehraufwand';
      }
    }
  }, [_vm._v("Mehraufwand")]) : _vm._e()]), _vm.selTab == 'doc' ? _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _small _mgrey bold"
  }, [_c('span', {
    staticClass: "icfolder color-white",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('ShowDatenModal');
      }
    }
  }, [_c('span', [_vm._v("Dateien")])])]), _c('div', {
    staticClass: "btn _small bold"
  }, [_c('span', {
    staticClass: "icfolder color-white",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openFotoMaker();
      }
    }
  }, [_c('span', [_vm._v("Camera")])])])]) : _vm._e(), _c('div', {
    staticClass: "bold"
  }, [_c('small', [_vm._v(_vm._s(_vm.topAddressLine))]), _c('small', [_vm._v("/  ")]), _c('small', [_c('router-link', {
    staticClass: "bold link",
    attrs: {
      "to": '/allianz/hvw/' + _vm.currentProject.hvUid
    }
  }, [_vm._v("HV:" + _vm._s((_vm._fbHausverwaltungen[_vm.currentProject.hvUid] || {}).name))])], 1), _c('div', {
    staticClass: "fr"
  }, [_c('small', {
    staticClass: "color-blue"
  }, [_vm._v(_vm._s(_vm.polNr))]), _c('small', [_vm._v(_vm._s(_vm.currentProject.consenseNr))])])])])]) : _vm._e()]), _c('div', {
    staticClass: "akt-content"
  }, [_vm.isLoadingAkt ? _c('div', {
    staticClass: "openakt"
  }, [!_vm.fbDataLoaded ? _c('div', {
    staticClass: "tac padding"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]) : _vm._e(), _vm.fbDataLoaded ? _c('div', {
    staticClass: "tac padding"
  }, [_c('p', [_vm._v("Akt ID:"), _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentProjectUid))]), _vm._v("existiert nicht oder wurde Ihnen nicht zugeordnet!")]), _c('router-link', {
    staticClass: "link bold",
    attrs: {
      "to": "/allianz/list"
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Zurück zur Übersichtsliste")])])], 1) : _vm._e()]) : !_vm.fbDataLoaded ? _c('div', {
    staticClass: "openakt"
  }, [_c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })])])]) : _c('div', {
    staticClass: "openakt"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'general',
      expression: "selTab == 'general'"
    }],
    staticClass: "full-height"
  }, [_c('AllianzAllgemein', {
    attrs: {
      "currentProject": _vm.currentProject
    },
    on: {
      "updateProject": function updateProject($event) {
        _vm.currentProject = $event;
      }
    }
  }), _vm._isGf ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'general',
      expression: "selTab == 'general'"
    }]
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    on: {
      "dblclick": function dblclick($event) {
        _vm.showdev = !_vm.showdev;
      }
    }
  }, [_vm._v("Developer")]), _vm.showdev ? _c('div', {
    staticClass: "card-content"
  }, [_c('pre', [_vm._v(_vm._s(_vm.changes))]), _c('pre', [_vm._v(_vm._s(_vm.currentProject))])]) : _vm._e()])]) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'hvw',
      expression: "selTab == 'hvw'"
    }]
  }, [_c('AllianzHvData', {
    attrs: {
      "currentProject": _vm.currentProject
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selTab == 'doc',
      expression: "selTab == 'doc'"
    }],
    staticClass: "full-height"
  }, [_c('AllianzDocument', {
    attrs: {
      "currentProject": _vm.currentProject,
      "flags": _vm.currentProject.flags
    },
    on: {
      "updateReportData": function updateReportData($event) {
        _vm.currentProject.reportData.html = $event;
      }
    }
  })], 1), _vm.selTab == 'files' ? _c('div', [_c('DateienAllianz', {
    attrs: {
      "reload": _vm.selTab == 'files',
      "view": 'tree',
      "currentProjectUid": _vm.currentProjectUid
    }
  })], 1) : _vm._e(), _vm.selTab == 'filesnew' ? _c('div', [_c('NewDateienAllianz', {
    attrs: {
      "reload": _vm.selTab == 'files',
      "view": 'tree',
      "currentProjectUid": _vm.currentProjectUid
    }
  })], 1) : _vm._e(), _vm.selTab == 'denkmalschutz' ? _c('div', [_c('Denkmalschutz', {
    attrs: {
      "currentProjectUid": _vm.currentProjectUid,
      "address": _vm.topAddressLine,
      "dsData": _vm.currentProject.dsData
    }
  })], 1) : _vm._e(), _vm.selTab == 'mehraufwand' ? _c('div', [_c('Mehraufwand', {
    attrs: {
      "currentProjectUid": _vm.currentProjectUid,
      "address": _vm.topAddressLine,
      "maData": _vm.currentProject.maData
    }
  })], 1) : _vm._e()]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowDatenModal",
      "name": "ShowDatenModal",
      "clickToClose": false,
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 840,
      "maxWidth": 640
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowDatenModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('DateienAllianz', {
    attrs: {
      "reload": _vm.selTab == 'doc',
      "view": 'mini',
      "currentProjectUid": _vm.currentProjectUid
    }
  })], 1)], 1), (_vm._isExternSv || _vm.isSv2) && (_vm.projectFromStore || {}).status == '774' ? _c('div', {
    attrs: {
      "id": "nosave-info-for-sv"
    }
  }, [_c('span', {
    staticClass: "tac"
  }, [_vm._v("Dieser Akt kann nicht gespeichert werden, da er schon für weitere Bearbeitung freigegeben ist. Sollten Änderungen zu tätigen sein, dann bitte den zuständigen SV oder das Consense Office kontaktieren.")])]) : _vm._isSv && (_vm.projectFromStore || {}).status == '525' ? _c('div', {
    attrs: {
      "id": "nosave-info-for-sv"
    }
  }, [_c('span', {
    staticClass: "tac"
  }, [_vm._v("Dieser Akt kann nicht gespeichert werden, da er schon fertiggestellt ist. Sollten Änderungen zu tätigen sein, dann bitte den zuständigen SV oder das Consense Office kontaktieren.")])]) : !_vm.isLoadingAkt ? _c('div', {
    class: {
      _opened: _vm.isProjectChanged,
      _finishColor: _vm.isToComplete
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": _vm.saveProject
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  }), _c('br'), _vm.isToComplete ? _c('span', {
    staticClass: "bold"
  }, [_vm._v("ABSCHLUSS")]) : _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])]) : _vm._e()])])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianzAllgemein"
  }, [!_vm.theCurrentProject.allianzData ? _c('span', [_vm._m(0)]) : _vm.theCurrentProject && (_vm._isAdmin || _vm._isSv) ? _c('div', [(_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus) && !_vm.isSv2 ? _c('div', [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link color-red",
    on: {
      "click": function click($event) {
        _vm.showAdmin = !_vm.showAdmin;
      }
    }
  }, [_vm._v("Admin")])]), _vm.showAdmin ? _c('div', {
    staticClass: "card-content"
  }, [_vm._isAdmin && ['525', '774'].includes((_vm.projectFromStore.status || '').toString()) ? _c('div', [_vm._m(1), _vm._m(2), ['525'].includes((_vm.projectFromStore.status || '').toString()) ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Rechnungsstellung an Allianz erledigt")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#6e92e2",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isAzBilled,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isAzBilled", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isAzBilled"
    }
  })], 1)]) : _vm._e(), ['525'].includes((_vm.projectFromStore.status || '').toString()) ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Allianz an Consense beglichen")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#6e92e2",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).hasAzPaid,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "hasAzPaid", $$v);
      },
      expression: "(theCurrentProject.flags || {}).hasAzPaid"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Externer SV Honorar gestellt/beglichen")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#6e92e2",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isSvPaid,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isSvPaid", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isSvPaid"
    }
  })], 1)])]) : _vm._e(), _c('h4', {
    staticClass: "color-blue"
  }, [_vm._v("SV Zuordnung")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Sachverständige SV1")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm._fbUsersSelectingArr,
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    model: {
      value: _vm.theCurrentProject.svUid,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "svUid", $$v);
      },
      expression: "theCurrentProject.svUid"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Sachverständige SV2")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm._fbUsersSelectingArr,
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    model: {
      value: _vm.theCurrentProject.svUid2,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "svUid2", $$v);
      },
      expression: "theCurrentProject.svUid2"
    }
  })], 1)])]) : _vm._e()])]) : _vm._e(), (_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus) && !_vm.isSv2 ? _c('div', [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.showAllgemein = !_vm.showAllgemein;
      }
    }
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "fr"
  }, [_c('StatusLights', {
    attrs: {
      "item": _vm.theCurrentProject
    }
  })], 1)]), _vm.showAllgemein ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.$_O2A(_vm._fbStatus.aktStatus),
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('span', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(option.icon) + " -")]), _c('span', [_vm._v(_vm._s(option.name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(option) {
        return [_c('span', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(option.icon) + " -")]), _c('span', [_vm._v(_vm._s(option.name))])];
      }
    }], null, false, 1363937229),
    model: {
      value: _vm.theCurrentProject.status,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "status", $$v);
      },
      expression: "theCurrentProject.status"
    }
  })], 1)]), _vm.theCurrentProject.status == '525' && _vm.projectFromStore.status != '525' ? _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "bold color-purple"
  }, [_vm._v("ABSCHLUSS: Bitte das PDF vor dem Abschließen kontrollieren!")])]) : _vm._e(), _c('div', {
    staticClass: "status"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Für Bearbeitung öffnen")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#ee5555",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isOpen", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isOpen"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Link versendet")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#85560a",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isSentEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isSentEmail", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isSentEmail"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("HV Daten vorhanden")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#f4a40b",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isFilledByHv,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isFilledByHv", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isFilledByHv"
    }
  }), (_vm.theCurrentProject.hvData || {}).modified ? _c('small', {
    staticClass: "fr"
  }, [_vm._v("ausgefüllt: " + _vm._s(_vm.$dayjs(_vm.theCurrentProject.hvData.modified).format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("SV Daten vorhanden")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#67ab7b",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isFilledBySv,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isFilledBySv", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isFilledBySv"
    }
  }), (_vm.theCurrentProject.hvData || {}).modified ? _c('small', {
    staticClass: "fr"
  }, [_vm._v("ausgefüllt: " + _vm._s(_vm.$dayjs(_vm.theCurrentProject.hvData.modified).format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Akt fertiggestellt")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#a735c1",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      },
      "disabled": ""
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isCompleted,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isCompleted", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isCompleted"
    }
  }), _c('small', [_vm._v("  wird automatisch gesetzt")]), _c('small', {
    staticClass: "fr color-purple"
  }, [(_vm.theCurrentProject.flags || {}).isCompleted ? _c('span', [_vm._v("am: " + _vm._s(_vm.$dayjs(_vm.theCurrentProject.completedDate).format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()])], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Urgenz/wichtig")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#e55",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isNewInJune,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isNewInJune", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isNewInJune"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Akt sichtbar")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#170",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isVisible", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isVisible"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Denkmalschutz")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#6eb595",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isDenkmalschutz,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isDenkmalschutz", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isDenkmalschutz"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Mehraufwand")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#6eb595",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isMehraufwand,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isMehraufwand", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isMehraufwand"
    }
  })], 1)]), _vm._isAdmin ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Eigenes PDF")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#335588",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isCustomPdf,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isCustomPdf", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isCustomPdf"
    }
  })], 1)]) : _vm._e(), _vm._isAdmin ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("neu ab 2023")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#335588",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).is2023Projects,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "is2023Projects", $$v);
      },
      expression: "(theCurrentProject.flags || {}).is2023Projects"
    }
  })], 1)]) : _vm._e(), _vm._isAdmin ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("MFH – S.Florian Projekt")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#325453",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isMfhSFP,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isMfhSFP", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isMfhSFP"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "spacer _vertical"
  }), _c('div', {
    staticClass: "sonstInfos"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Notizen Office")]), _c('TipTap', {
    attrs: {
      "content": _vm.theCurrentProject,
      "ckey": 'generalInfo'
    }
  })], 1), _c('div', {
    staticClass: "spacer _vertical"
  }), _vm._isAdmin ? _c('div', {
    staticClass: "pdfregler"
  }, [_c('h4', [_vm._v("PDF Regler:")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Massenerm. anhand Grundbuchauszug")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#335588",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      },
      "disabled": !(_vm.theCurrentProject.flags || {}).isPdfMassErmVomGrundbuch
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isPdfMassErmVomGrundbuch,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isPdfMassErmVomGrundbuch", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isPdfMassErmVomGrundbuch"
    }
  }), _vm._m(3)], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Keine Kontaktdaten vorhanden/erhalten")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#335588",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isPdfNoContactAvailable,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isPdfNoContactAvailable", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isPdfNoContactAvailable"
    }
  }), _c('small', {
    staticClass: "fr"
  }, [_vm._v("Auf dem pdf wird vermerkt, dass keine Kontaktdaten von Allianz zur Verfügung gestellt wurden")])], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _large"
  }, [_vm._v("Keine Planunterlagen von HV/VN")]), _c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#335588",
      "height": 18,
      "width": 56,
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: (_vm.theCurrentProject.flags || {}).isPdfNoDocsAvailable,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject.flags || {}, "isPdfNoDocsAvailable", $$v);
      },
      expression: "(theCurrentProject.flags || {}).isPdfNoDocsAvailable"
    }
  }), _c('small', {
    staticClass: "fr"
  }, [_vm._v("Auf dem pdf wird vermerkt, dass keine Planunterlagen von Versicherungsnehmer/Verwalter zur Verfügung gestellt wurden")])], 1)])]) : _vm._e()]) : _vm._e()])]) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus || _vm._isExternSv ? _c('div', [_c('AllianzAktSvData', {
    attrs: {
      "svData": _vm.theCurrentProject.svData || {},
      "isSv2": _vm.isSv2
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('div', [_c('AllianzAktContacts', {
    attrs: {
      "hvUidFromParent": _vm.theCurrentProject.hvUid || '',
      "emailReceiverFromParent": _vm.theCurrentProject.emailReceiver || ''
    },
    on: {
      "updateEmailReceiver": function updateEmailReceiver($event) {
        _vm.theCurrentProject.emailReceiver = $event;
      },
      "updateHvUid": function updateHvUid($event) {
        _vm.theCurrentProject.hvUid = $event;
      }
    },
    model: {
      value: _vm.theCurrentProject.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "contact", $$v);
      },
      expression: "theCurrentProject.contact"
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus || _vm._isExternSv ? _c('div', [_vm.isSv2 ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Allgemeine Infos vom Office:")]), _c('TextareaAutosize', {
    staticClass: "_smaller-text",
    attrs: {
      "placeholder": "",
      "min-height": 80,
      "max-height": 480,
      "disabled": ""
    },
    model: {
      value: _vm.theCurrentProject.generalInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "generalInfo", $$v);
      },
      expression: "theCurrentProject.generalInfo"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "card AllianzAktSvNotice"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingNotizen = !_vm.isShowingNotizen;
      }
    }
  }, [_vm._v("Notizen")])]), _vm.isShowingNotizen ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Notizen SV")]), _c('TipTap', {
    attrs: {
      "content": _vm.theCurrentProject,
      "ckey": 'notices'
    }
  }), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Notizen SV2 (Kontrolle)")]), _c('TipTap', {
    attrs: {
      "content": _vm.theCurrentProject,
      "ckey": 'noticesSv2'
    }
  })], 1) : _vm._e()])]) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('div', [_c('AllianzAktRawData', {
    attrs: {
      "currentCoordinates": _vm.theCurrentProject.loc
    },
    on: {
      "updateLocation": function updateLocation($event) {
        _vm.theCurrentProject.loc = $event;
      }
    },
    model: {
      value: _vm.theCurrentProject.allianzData,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "allianzData", $$v);
      },
      expression: "theCurrentProject.allianzData"
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus || _vm._isExternSv ? _c('div', [_c('AllianzLinksForMaps', {
    attrs: {
      "allianzData": _vm.theCurrentProject.allianzData || {},
      "loc": _vm.theCurrentProject.loc || {}
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('div', [_c('AllianzAktHistory', {
    attrs: {
      "id": _vm.theCurrentProject.id || null
    }
  })], 1) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fl"
  }, [_c('h4', {
    staticClass: "color-blue"
  }, [_vm._v("Zahlungen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fr"
  }, [_c('small', [_vm._v("(nur für admin bei Z1 sichtbar)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('small', [_c('div', {
    staticClass: "fr"
  }, [_vm._v("Auf dem pdf wird vermerkt, dass die Massenermittlung nur anhand des Grundbuchauszuges stattfand.")]), _c('div', {
    staticClass: "fr color-red"
  }, [_vm._v("* Wird automatisch gesetzt, sobald der Status auf \"E0\" ist; Kann manuell nur deaktiviert, aber nicht manuell aktiviert werden!")])]);
}];
export { render, staticRenderFns };
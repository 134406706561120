import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters, mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import StatusLights from "@/views/Allianz/TheAkt/Allgemein/StatusLights";
import AllianzLinksForMaps from "@/views/Allianz/TheAkt/Allgemein/AllianzLinksForMaps";
import AllianzAktSvData from "@/views/Allianz/TheAkt/Allgemein/AllianzAktSvData";
import AllianzAktRawData from "@/views/Allianz/TheAkt/Allgemein/AllianzAktRawData";
import AllianzAktContacts from "@/views/Allianz/TheAkt/Allgemein/AllianzAktContacts";
import AllianzAktHistory from "@/views/Allianz/TheAkt/Allgemein/AllianzAktHistory";
var self = null;
export default {
  name: "AllianzAllgemein",
  beforeCreate: function beforeCreate() {
    self = this;
  },
  components: {
    AllianzLinksForMaps: AllianzLinksForMaps,
    StatusLights: StatusLights,
    AllianzAktSvData: AllianzAktSvData,
    AllianzAktRawData: AllianzAktRawData,
    AllianzAktContacts: AllianzAktContacts,
    AllianzAktHistory: AllianzAktHistory
  },
  props: {
    currentProject: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isWaitingForResponse: false,
      theCurrentProject: {},
      showAdmin: true,
      showAllgemein: true,
      isShowingNotizen: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["_fbStatus", "_fbUsersSelectingArr", "_fbHausverwaltungen", "_fbProjects"])), {}, {
    _fbUsersObj: function _fbUsersObj() {
      return self.$_.keyBy(self._fbUsersSelectingArr, "id");
    },
    isSv2: function isSv2() {
      return self.currentProject.svUid2 == self._userUid && self.currentProject.svUid != self._userUid;
    },
    _isExternAndSecondSv: function _isExternAndSecondSv() {
      return self.isSv2 && self._isExternSv;
    },
    projectFromStore: function projectFromStore() {
      return self._fbProjects[self.currentProject.id] || null;
    }
    // changes: () => self.$_getDifferences(self.projectFromStore, self.currentProject)
  }),
  mounted: function mounted() {},
  watch: {
    currentProject: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        if (self.$_isDifferent(nv, self.theCurrentProject)) self.$set(self, "theCurrentProject", self.$_cp(nv));
      }
    },
    theCurrentProject: {
      deep: true,
      handler: _.debounce(function (nv) {
        if (self.$_isDifferent(nv, self.currentProject)) self.$emit("updateProject", self.$_cp(self.theCurrentProject));
      }, 250)
    }
  },
  methods: {}
};
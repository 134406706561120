import { mapState, mapGetters } from "vuex";
import DateienAllianz from "../Dateien/DateienAllianz.vue";
// import FotoMaker from "../Dateien/FotoMaker.vue";
import env from "@/env";
import doctemplate from "@/assets/json/doctemplate";
import EXCEL from "./EXCEL.vue";
import EXCEL2 from "./EXCEL2.vue";
import EXCEL3 from "./EXCEL3.vue";
import HeadCalc from "./HeadCalc.vue";
import PDFexporter from "./PDFexporter";
import mixinDoc from "./mixinDoc";
// import DerBericht from "./DerBericht.vue";
var self = null;
var keineagabe = "KEINE ANGABE";
export default {
  components: {
    // FotoMaker,
    DateienAllianz: DateienAllianz,
    EXCEL: EXCEL,
    EXCEL2: EXCEL2,
    EXCEL3: EXCEL3,
    HeadCalc: HeadCalc
  },
  mixins: [PDFexporter, mixinDoc],
  beforeCreate: function beforeCreate() {
    self = this;
  },
  data: function data() {
    return {
      showHistory: false,
      isWaitingForResponse: false,
      excelID: 5,
      buildingsID: 4,
      vals: self.$_cp(doctemplate.vals),
      selBuilding: 0,
      // SELECTED BUILDING FOR EDITING
      selectetedType: {},
      selectetedImageIdx: null,
      selectetedImageIdxTMP: null,
      HTML: "",
      NewFieldForAtts: "",
      NewFieldForAttsHEAD: "",
      newfield: {
        bidx: -1,
        bbidx: -1,
        ix: -1,
        idx: -1
      },
      newfieldHEAD: {
        ix: -1,
        idx: -1
      },
      // tok
      theCurrentProject: {},
      Bericht: [],
      ChooseThis: {},
      // AllTypes–––––––––––––––––––––
      AllTypes: self.$_cp(doctemplate.AllTypes),
      isReportDataLoaded: false,
      isReportDataLoadedSOMEOTHERERROR: false,
      ADDNEW: false
    };
  },
  props: ["currentProject", "flags"],
  // computed: {},
  methods: {
    scrollToDocItem: function scrollToDocItem(ix) {
      setTimeout(function () {
        var options = {
          container: ".doc-content",
          easing: "ease",
          offset: -12,
          cancelable: true,
          x: false,
          y: true
        };
        self.$scrollTo("li.MainBlock.item_" + ix, 300, options);
      }, 100);
    }
  }
};